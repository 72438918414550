import React from 'react';

const Icon01d = ({color, size}) => {
    return (
        <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <path
        fill="#feef7f"
        d="M400.781 255.5c0 79.684-64.594 144.281-144.281 144.281-79.684 0-144.281-64.597-144.281-144.281 0-79.688 64.597-144.281 144.281-144.281 79.688 0 144.281 64.594 144.281 144.281zm0 0"
      ></path>
      <path
        fill="#fff"
        d="M216.422 247.984h16.031v15.032h-16.031zm0 0M248.484 247.984h16.032v15.032h-16.032zm0 0M280.547 247.984h16.031v15.032h-16.031zm0 0"
      ></path>
      <g fill="#ea9a10">
        <path d="M256.5 111.219c-4.05 0-8.059.172-12.023.5 74.058 6.113 132.257 68.144 132.257 143.781s-58.199 137.668-132.257 143.781c3.964.324 7.972.5 12.023.5 79.688 0 144.281-64.597 144.281-144.281S336.187 111.219 256.5 111.219zm0 0M280.547 79.156h-48.094l22.043-72.14h4.008zm0 0M232.453 431.844h48.094l-22.043 72.14h-4.008zm0 0M398.2 147.809L364.19 113.8l66.598-35.426 2.836 2.836zm0 0M114.805 363.188l34.007 34.007-66.601 35.426-2.832-2.832zm0 0M432.844 279.547v-48.094l72.14 22.043v4.008zm0 0M80.156 231.453v48.094l-72.14-22.043v-4.008zm0 0M364.191 397.195l34.008-34.007 35.426 66.601-2.836 2.832zm0 0M148.813 113.8l-34.008 34.009L79.379 81.21l2.832-2.836zm0 0"></path>
      </g>
      <path d="M256.5 103.703c-83.7 0-151.797 68.094-151.797 151.797 0 83.7 68.098 151.797 151.797 151.797 83.703 0 151.797-68.098 151.797-151.797 0-83.703-68.094-151.797-151.797-151.797zm0 288.563c-75.414 0-136.766-61.352-136.766-136.766S181.086 118.73 256.5 118.73s136.77 61.356 136.77 136.77-61.356 136.766-136.77 136.766zm0 0"></path>
      <path d="M152.797 255.5h15.031c0-48.895 39.777-88.676 88.672-88.676v-15.027c-57.18 0-103.703 46.52-103.703 103.703zm0 0M264.066 0h-15.132L222.3 87.172h68.402zM256.5 26.672l13.895 45.469h-27.786zm0 0M248.934 512h15.132l26.637-87.172h-68.402zm7.566-26.672l-13.89-45.469h27.785zm0 0M442.871 79.828l-10.7-10.7-80.472 42.806 48.367 48.367zm-66.187 35.836L418.66 93.34l-22.324 41.976zm0 0M70.133 431.168l10.699 10.7 80.473-42.805-48.368-48.368zm66.183-35.836L94.344 417.66l22.324-41.976zm0 0M424.828 221.297v68.402L512 263.066v-15.132zm15.031 48.094v-27.786l45.47 13.895zm0 0M87.172 221.297L0 247.934v15.132L87.172 289.7zM72.14 269.39L26.67 255.5l45.47-13.895zm0 0M351.7 399.063l80.472 42.804 10.7-10.699-42.806-80.473zm24.984-3.731l19.648-19.648 22.328 41.976zm0 0M161.305 111.934L80.832 69.133l-10.7 10.695 42.805 80.473zm-24.989 3.73l-19.648 19.648L94.344 93.34zm0 0"></path>
    </svg>
    );
};

Icon01d.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon01d;