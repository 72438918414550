import React from "react";

const Icon03d = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -88 512 512"
    >
      <path
        fill="#b8ecff"
        d="M504.484 259.633c0-37.63-30.504-68.133-68.132-68.133-4.817 0-9.516.508-14.051 1.457a161.463 161.463 0 002.027-25.504c0-88.539-71.773-160.312-160.312-160.312-83.278 0-151.715 63.496-159.555 144.714a89.212 89.212 0 00-8.773-.433c-48.696 0-88.172 39.476-88.172 88.172s39.476 88.172 88.172 88.172h344.671v-.13c35.762-2.073 64.125-31.722 64.125-68.003zm0 0"
      ></path>
      <g fill="#fff">
        <path d="M215.922 280.172h16.031v15.031h-16.031zm0 0M247.984 280.172h16.032v15.031h-16.032zm0 0M280.047 280.172h16.031v15.031h-16.031zm0 0"></path>
      </g>
      <path
        fill="#57bfe6"
        d="M400.281 167.453c0 8.68-.699 17.195-2.027 25.504a68.526 68.526 0 0124.152-.707 161.166 161.166 0 001.922-24.797c0-88.539-71.773-160.312-160.312-160.312-4.043 0-8.051.152-12.02.445 82.922 6.152 148.285 75.375 148.285 159.867zm0 0M436.352 191.5a68.29 68.29 0 00-12.004 1.082c31.882 5.688 56.09 33.535 56.09 67.05 0 36.282-28.364 65.93-64.125 68.005v.129h24.046v-.13c35.762-2.074 64.125-31.722 64.125-68.003 0-37.63-30.504-68.133-68.132-68.133zm0 0"
      ></path>
      <path d="M436.352 184.484h-5.309c.527-6.011.8-11.222.8-16.78C431.844 75.16 356.556 0 264.017 0 179.465 0 109.332 62.246 97.848 144.406h-2.16C42.925 144.406 0 187.332 0 240.094s42.926 95.687 95.688 95.687h340.664c41.714 0 75.648-33.937 75.648-75.648s-33.934-75.649-75.648-75.649zm0 136.266H95.688c-44.477 0-80.657-36.18-80.657-80.656s36.18-80.656 80.656-80.656h.715c-.14 2.003-.215 5.41-.215 8.265 0 11.293 1.13 22.461 3.36 33.434l14.726-3.055a154.312 154.312 0 01-3.054-30.598c0-84.254 68.543-152.816 152.797-152.816s152.796 68.539 152.796 152.793c0 33.375-10.562 65.078-30.554 91.684l12.015 9.277c15.547-20.692 25.899-44.86 30.622-68.906h7.457c33.425 0 60.617 27.191 60.617 60.617s-27.192 60.617-60.617 60.617zm0 0"></path>
      <path d="M152.297 167.453h15.031c0-53.316 43.375-96.691 96.688-96.691V55.734c-61.602 0-111.72 50.118-111.72 111.72zm0 0"></path>
    </svg>
    );
};

Icon03d.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon03d;