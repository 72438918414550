import React from 'react';

const Icon01n = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -88 512 512"
    >
      <path
        fill="#feef7f"
        d="M416 167.5c0 88.367-71.633 160-160 160s-160-71.633-160-160 71.633-160 160-160 160 71.633 160 160zm0 0"
      ></path>
      <path
        fill="#fff"
        d="M216 160h16v15h-16zm0 0M248 160h16v15h-16zm0 0M280 160h16v15h-16zm0 0"
      ></path>
      <path
        fill="#ea9a10"
        d="M256 7.5c-4.035 0-8.04.152-12 .445 82.762 6.14 148 75.227 148 159.555s-65.238 153.414-148 159.555c3.96.293 7.965.445 12 .445 88.363 0 160-71.633 160-160s-71.637-160-160-160zm0 0"
      ></path>
      <g fill="#fff">
        <path d="M512 183.5c-17.672 0-32 14.328-32 32 0-17.672-14.328-32-32-32 17.672 0 32-14.328 32-32 0 17.672 14.328 32 32 32zm0 0M448 119.5c-17.672 0-32 14.328-32 32 0-17.672-14.328-32-32-32 17.672 0 32-14.328 32-32 0 17.672 14.328 32 32 32zm0 0M80 215.5c-17.672 0-32 14.328-32 32 0-17.672-14.328-32-32-32 17.672 0 32-14.328 32-32 0 17.672 14.328 32 32 32zm0 0M64 103.5c-17.672 0-32 14.328-32 32 0-17.672-14.328-32-32-32 17.672 0 32-14.328 32-32 0 17.672 14.328 32 32 32zm0 0"></path>
      </g>
      <path
        fill="#ea9a10"
        d="M216 119.5c0 8.836-7.164 16-16 16s-16-7.164-16-16 7.164-16 16-16 16 7.164 16 16zm0 0M232 231.5c0 8.836-7.164 16-16 16s-16-7.164-16-16 7.164-16 16-16 16 7.164 16 16zm0 0M176 175.5c0 13.254-10.746 24-24 24s-24-10.746-24-24 10.746-24 24-24 24 10.746 24 24zm0 0"
      ></path>
      <path d="M256 48v15c57.621 0 104.5 46.879 104.5 104.5h15C375.5 101.605 321.895 48 256 48zm0 0"></path>
      <path d="M256 320c-84.09 0-152.5-68.41-152.5-152.5S171.91 15 256 15c27.25 0 53.988 7.27 77.324 21.027a153.4 153.4 0 0154.781 55.235l12.985-7.512c-14.461-25.004-35.262-45.973-60.149-60.645C315.301 7.988 285.926 0 256 0 163.64 0 88.5 75.14 88.5 167.5S163.64 335 256 335s167.5-75.14 167.5-167.5h-15c0 84.09-68.41 152.5-152.5 152.5zm0 0M487.5 151.5h-15c0 13.512-10.992 24.5-24.5 24.5v15c13.508 0 24.5 10.988 24.5 24.5h15c0-13.512 10.992-24.5 24.5-24.5v-15c-13.508 0-24.5-10.988-24.5-24.5zm-7.5 40.883a39.924 39.924 0 00-8.883-8.883 39.924 39.924 0 008.883-8.883 39.924 39.924 0 008.883 8.883 39.924 39.924 0 00-8.883 8.883zm0 0"></path>
      <path d="M448 112c-13.508 0-24.5-10.988-24.5-24.5h-15c0 13.512-10.992 24.5-24.5 24.5v15c13.508 0 24.5 10.988 24.5 24.5h15c0-13.512 10.992-24.5 24.5-24.5zm-32 16.383a39.924 39.924 0 00-8.883-8.883 39.924 39.924 0 008.883-8.883 39.924 39.924 0 008.883 8.883 39.924 39.924 0 00-8.883 8.883zm0 0M55.5 183.5h-15c0 13.512-10.992 24.5-24.5 24.5v15c13.508 0 24.5 10.988 24.5 24.5h15c0-13.512 10.992-24.5 24.5-24.5v-15c-13.508 0-24.5-10.988-24.5-24.5zM48 224.383a39.924 39.924 0 00-8.883-8.883A39.924 39.924 0 0048 206.617a39.924 39.924 0 008.883 8.883A39.924 39.924 0 0048 224.383zm0 0M24.5 135.5h15c0-13.512 10.992-24.5 24.5-24.5V96c-13.508 0-24.5-10.988-24.5-24.5h-15C24.5 85.012 13.508 96 0 96v15c13.508 0 24.5 10.988 24.5 24.5zM32 94.617a39.924 39.924 0 008.883 8.883A39.924 39.924 0 0032 112.383a39.924 39.924 0 00-8.883-8.883A39.924 39.924 0 0032 94.617zm0 0M223.5 119.5c0-12.957-10.543-23.5-23.5-23.5s-23.5 10.543-23.5 23.5S187.043 143 200 143s23.5-10.543 23.5-23.5zm-32 0c0-4.688 3.813-8.5 8.5-8.5s8.5 3.813 8.5 8.5-3.813 8.5-8.5 8.5-8.5-3.813-8.5-8.5zm0 0M216 255c12.957 0 23.5-10.543 23.5-23.5S228.957 208 216 208s-23.5 10.543-23.5 23.5S203.043 255 216 255zm0-32c4.688 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5-8.5-3.813-8.5-8.5 3.813-8.5 8.5-8.5zm0 0M152 144c-17.367 0-31.5 14.133-31.5 31.5S134.633 207 152 207s31.5-14.133 31.5-31.5S169.367 144 152 144zm0 48c-9.098 0-16.5-7.402-16.5-16.5S142.902 159 152 159s16.5 7.402 16.5 16.5S161.098 192 152 192zm0 0"></path>
    </svg>
    );
};

Icon01n.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon01n;