import React from 'react';

const CreativeIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="-22 0 447 447.682"
    >
      <path d="M261.18 229.734l-27.118 27.121c-.082.079-.082.079-.082.16l-27.199 27.2c55.598 46.879 101.04 65.84 125.52 70.879-5.04-23.598-24-69.52-71.121-125.36zm0 0M195.18 163.734C139.5 116.855 94.062 97.894 69.582 92.855c5.04 23.598 24 69.438 71.117 125.282l27.203-27.203c.078 0 .078 0 .16-.079zm0 0M189.66 223.977l-16.078-16.082-22.32 22.32a625.977 625.977 0 0015.52 16.558zm0 0M217.02 251.336l-16.079-16.082-22.8 22.883c5.28 5.117 10.8 10.238 16.558 15.52zm0 0M398.46 82.137l4.4-48.801-25.36 25.437a8.112 8.112 0 01-11.36 0 8.06 8.06 0 010-11.359l25.442-25.36-48.8 4.4-22.72 45.6 32.797 32.801zm0 0M184.938 196.61l91.199-91.2 43.36 43.36-91.2 91.203zm0 0M333.18 107.734l-16-16-16.078 16 16.078 16.082zm0 0M.46 424.453l48.4-13.2-35.2-35.198zm0 0M167.582 54.613c12.484-12.5 12.477-32.754-.02-45.246-12.496-12.488-32.75-12.488-45.246 0-12.496 12.492-12.504 32.746-.015 45.246 12.672 12.09 32.605 12.09 45.281 0zm0 0M256.941 383.977a31.996 31.996 0 00-29.59 19.75 31.994 31.994 0 006.95 34.886c12.672 12.09 32.605 12.09 45.281 0a32 32 0 00-22.64-54.636zm0 0M107.66 305.977c-5.36-5.524-10.558-11.043-15.52-16.563l-71.28 71.281 2.722 2.64 13.36 13.438zm0 0M48.219 388.055l16 16 71.281-71.282c-5.52-4.957-11.04-10.16-16.559-15.52zm0 0"></path>
      <path d="M272.46 218.453c58.24 68.64 76.08 123.602 77.2 145.684a7.944 7.944 0 01-8 8.398h-.48c-30.801-1.922-89.598-28-151.52-81.84-10.32-9.199-19.68-17.922-28.558-26.879-9.04-8.96-17.84-18.32-26.883-28.562-62.156-71.36-80.797-128.719-82-151.438a8.018 8.018 0 012.36-6.101 8.01 8.01 0 016.12-2.3c29.84 1.839 86.082 26.48 145.762 77.038l36.559-36.558a527.472 527.472 0 00-65.758-48.56 47.958 47.958 0 01-51.692 8.509 47.957 47.957 0 01-28.629-43.867c0-.641.079-1.282.079-1.922-36.957-10.48-65.84-7.762-81.84 8.16-37.52 37.52-1.758 138.238 83.199 234.32 8.16 9.281 16.96 18.719 26.16 27.84 9.203 9.121 18.563 18 27.84 26.16a528.764 528.764 0 0072.242 54.078 47.951 47.951 0 0151.688-8.508 47.956 47.956 0 0128.632 43.872c0 .636-.082 1.277-.082 1.918 36.961 10.48 65.84 7.761 81.84-8.16 36.64-36.72 3.203-133.758-77.68-227.84zm0 0"></path>
    </svg>
    );
};

CreativeIcon.defaultProps = {
    color: "white",
    size: 20
};

export default CreativeIcon;