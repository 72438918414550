import React from "react";

const Icon02n = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -28 512 512"
    >
      <path
        fill="#feef7f"
        d="M199.902 80.156C186.5 70.09 169.848 64.125 151.797 64.125c-44.27 0-80.156 35.887-80.156 80.156s35.886 80.156 80.156 80.156c18.047 0 34.703-5.964 48.101-16.03l8.008 8.015c-20.586 24.504-53.664 40.078-88.172 40.078-61.976 0-112.218-50.242-112.218-112.219S57.758 32.062 119.734 32.062c34.508 0 67.586 15.575 88.172 40.079zm0 0"
      ></path>
      <path
        fill="#daedf7"
        d="M456.39 352.688c-1.378 0-2.742.074-4.09.187a128.505 128.505 0 004.09-32.25c0-70.828-57.417-128.25-128.25-128.25-70.827 0-128.25 57.422-128.25 128.25-35.413 0-64.124 28.71-64.124 64.125 0 35.418 28.71 64.125 64.125 64.125h256.5c26.562 0 48.093-21.531 48.093-48.094 0-26.558-21.53-48.094-48.093-48.094zm0 0"
      ></path>
      <path
        fill="#8bb4d9"
        d="M456.39 352.688c-1.378 0-2.742.074-4.09.187a128.505 128.505 0 004.09-32.25c0-70.828-57.417-128.25-128.25-128.25-4.054 0-8.062.2-12.023.566 65.192 6.063 116.227 60.91 116.227 127.684 0 11.14-1.422 21.945-4.09 32.25a48.778 48.778 0 014.09-.188c26.562 0 48.094 21.536 48.094 48.094 0 26.563-21.532 48.094-48.094 48.094h24.047c26.562 0 48.093-21.531 48.093-48.094 0-26.558-21.53-48.094-48.093-48.094zm0 0"
      ></path>
      <g fill="#fff">
        <path d="M280.047 401.285h16.031v15.027h-16.031zm0 0M312.11 401.285h16.03v15.027h-16.03zm0 0M344.172 401.285h16.031v15.027h-16.031zm0 0M119.734 32.063c-17.707 0-32.062 14.359-32.062 32.062 0-17.703-14.36-32.063-32.063-32.063 17.703 0 32.063-14.359 32.063-32.062 0 17.703 14.355 32.063 32.062 32.063zm0 0M127.75 312.61c-17.703 0-32.063 14.359-32.063 32.062 0-17.703-14.359-32.063-32.062-32.063 17.703 0 32.063-14.355 32.063-32.062 0 17.707 14.359 32.062 32.062 32.062zm0 0M247.984 144.281c-17.703 0-32.062 14.36-32.062 32.063 0-17.703-14.36-32.063-32.063-32.063 17.703 0 32.063-14.36 32.063-32.062 0 17.703 14.36 32.062 32.062 32.062zm0 0"></path>
      </g>
      <path d="M213.66 221.258l4.43-5.274-17.488-17.504-5.22 3.918c-12.644 9.504-27.718 14.524-43.585 14.524-40.055 0-72.64-32.586-72.64-72.64s32.585-72.641 72.64-72.641c15.867 0 30.941 5.023 43.59 14.527l5.218 3.922 17.485-17.512-4.43-5.27c-18.664-22.218-46.676-37.37-76.86-41.574l-2.07 14.887c21.168 2.95 41.086 11.973 56.551 25.348-12.152-6.156-25.566-9.36-39.484-9.36-48.344 0-87.672 39.328-87.672 87.672s39.328 87.672 87.672 87.672c13.883 0 27.262-3.187 39.387-9.308-19.223 16.5-45.38 26.34-71.45 26.34-57.734 0-104.703-46.97-104.703-104.704 0-34.21 16.774-66.34 44.875-85.941L51.31 46.016C19.179 68.422 0 105.16 0 144.28c0 66.024 53.71 119.735 119.734 119.735 36.32 0 71.434-15.985 93.926-42.758zm0 0M461.602 345.426a135.288 135.288 0 002.304-24.801c0-74.86-60.902-135.766-135.765-135.766-72.465 0-131.852 57.07-135.579 128.625-36.074 3.68-64.312 34.489-64.312 71.52 0 39.5 32.137 71.887 71.64 71.887h256.5c30.665 0 55.61-25.196 55.61-55.856 0-28.906-22.172-52.972-50.398-55.61zm-5.211 96.437h-256.5c-31.215 0-56.61-25.644-56.61-56.86 0-28.75 21.547-52.671 49.336-56.253.512 8.777 1.86 17.41 4.035 25.875l14.555-3.777a121.368 121.368 0 01-3.805-30.207c0-66.575 54.164-120.746 120.739-120.746s120.734 54.16 120.734 120.734c0 27.758-9.152 53.848-26.477 75.457l11.727 9.398c10.79-13.457 18.852-28.867 23.852-45.238 21.64.836 38.992 18.945 38.992 40.79 0 22.374-18.203 40.827-40.578 40.827zm0 0"></path>
      <path d="M240.469 320.625H255.5c0-40.055 32.586-72.64 72.64-72.64v-15.032c-48.343 0-87.671 39.332-87.671 87.672zm0 0M80.156 64.125h15.031c0-13.535 11.012-24.547 24.547-24.547V24.547C106.2 24.547 95.188 13.535 95.188 0H80.156c0 13.535-11.011 24.547-24.547 24.547v15.031c13.536 0 24.547 11.012 24.547 24.547zm7.516-40.965a39.853 39.853 0 008.902 8.902 39.853 39.853 0 00-8.902 8.903 39.935 39.935 0 00-8.902-8.903 39.935 39.935 0 008.902-8.902zm0 0M103.203 280.547H88.172c0 13.535-11.012 24.55-24.547 24.55v15.028c13.535 0 24.547 11.012 24.547 24.547h15.031c0-13.535 11.012-24.547 24.547-24.547v-15.027c-13.535 0-24.547-11.016-24.547-24.551zm-7.516 40.965a39.935 39.935 0 00-8.902-8.903 39.917 39.917 0 008.903-8.898 39.836 39.836 0 008.902 8.898 39.853 39.853 0 00-8.903 8.903zm0 0M183.86 136.766v15.03c13.535 0 24.546 11.013 24.546 24.548h15.031c0-13.535 11.012-24.547 24.547-24.547v-15.031c-13.535 0-24.547-11.012-24.547-24.547h-15.03c0 13.535-11.012 24.547-24.548 24.547zm32.062-1.387a39.853 39.853 0 008.902 8.902 39.935 39.935 0 00-8.902 8.903 39.935 39.935 0 00-8.902-8.903 39.853 39.853 0 008.902-8.902zm0 0"></path>
    </svg>
    );
};

Icon02n.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon02n;