import React from 'react';

const EmptyBoxIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 511 511"
    >
      <path d="M426.107 279.5l71.499-71.5H364.394l-46.197 46.196 10.607 10.607L370.606 223h90.787l-49.001 49H210.607l-64.001-64H13.394l71.5 71.5-71.5 71.5h229.213L296 297.606V496H103V367.5H88V511h335V351h74.606zM140.394 223l48.999 49H98.607l-49.001-49zm96 113H49.606l49-49h186.787zM408 496h-97V287h97zm15-198.393L461.394 336H423z"></path>
      <path d="M177.632 221.368l60.16-24.94A87.422 87.422 0 00279.5 207c48.248 0 87.5-39.252 87.5-87.5S327.748 32 279.5 32 192 71.252 192 119.5a87.422 87.422 0 0010.572 41.708zm39.703-64.554A72.369 72.369 0 01207 119.5c0-39.977 32.523-72.5 72.5-72.5S352 79.523 352 119.5 319.477 192 279.5 192a72.369 72.369 0 01-37.314-10.335l-3.243-1.952-33.574 13.919 13.919-33.574zM159.5 47c12.958 0 23.5-10.542 23.5-23.5S172.458 0 159.5 0 136 10.542 136 23.5 146.542 47 159.5 47zm0-32c4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5-8.5-3.813-8.5-8.5 3.813-8.5 8.5-8.5zM408 143.5c0 12.958 10.542 23.5 23.5 23.5s23.5-10.542 23.5-23.5-10.542-23.5-23.5-23.5-23.5 10.542-23.5 23.5zm23.5-8.5c4.687 0 8.5 3.813 8.5 8.5s-3.813 8.5-8.5 8.5-8.5-3.813-8.5-8.5 3.813-8.5 8.5-8.5z"></path>
      <path d="M260.5 167.106l19-19 19 19 28.606-28.606-19-19 19-19L298.5 71.894l-19 19-19-19-28.606 28.606 19 19-19 19zm-7.394-66.606l7.394-7.394 19 19 19-19 7.394 7.394-19 19 19 19-7.394 7.394-19-19-19 19-7.394-7.394 19-19zM104 119.5h15V103h16.5V88H119V71.5h-15V88H87.5v15H104zM392 71.5h15V55h16.5V40H407V23.5h-15V40h-16.5v15H392zM159.5 456h16v15h-16zM191.5 456h16v15h-16zM223.5 456h16v15h-16zM340.804 444.804L352 433.607V471.5h15v-37.893l11.196 11.197 10.608-10.608-29.304-29.302-29.304 29.302z"></path>
    </svg>
    );
};

EmptyBoxIcon.defaultProps = {
    color: "white",
    size: 18
};

export default EmptyBoxIcon;