import React from 'react';

const EarIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512.003 512.003"
      fill={color}
    >
      <path d="M457.341 302.061l-28.104-10.499c14.458-38.699 21.788-79.112 21.788-120.116 0-42.8-10.628-85.484-30.736-123.438l26.51-14.045c22.392 42.264 34.227 89.805 34.227 137.482-.001 44.601-7.97 88.547-23.685 130.616zM407.812 261.242l-28.736-8.613c7.929-26.456 11.949-53.77 11.949-81.183 0-29.859-6.36-58.769-18.903-85.926l27.234-12.58c14.379 31.129 21.669 64.271 21.669 98.506 0 30.332-4.445 60.544-13.213 89.796zM196.023 0c-62.53 0-165 51.03-165 165 0 40.94 15.11 80.3 42.6 110.89 8 8.8 13.23 25.29.81 35.96-19.78 17.01-33.52 39.07-39.73 63.8-16.54 65.85 23.29 134.05 93.82 136.27 61.41 1.94 82.88-30.17 105.31-78 15.569-33.228 17.99-35.145 38.31-60.25 45.71-56.59 88.88-110.05 88.88-202.37 0-68.246-50.176-171.3-165-171.3zm36.87 324.37l-23.43-18.74c59.79-74.75 78-141.28 49.97-182.52-18.48-27.19-56.21-39.45-89.71-29.18-8.04 2.47-15.19 6.04-21.36 10.61 38.17 20.72 62.66 60.89 62.66 105.46 0 17.99-3.89 35.3-11.55 51.44l-27.1-12.88c5.74-12.08 8.65-25.06 8.65-38.56 0-35.43-20.63-67.15-52.23-81.73-5.07 10.48-7.75 22.82-7.77 36.61 0 9.35 1.69 18.48 5.01 27.09l-28 10.79c-4.65-12.07-7.01-24.82-7.01-37.91.08-48.96 26.2-86.19 69.9-99.6 43.955-13.493 96.33 1.282 123.33 41 44.859 66.028-.971 155.107-51.36 218.12z"></path>
    </svg>
    );
};

EarIcon.defaultProps = {
    color: "white",
    size: 18
};

export default EarIcon;