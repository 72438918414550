import React from 'react';

const SupportIcon = ({color, size}) => {
    return (
<svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      fill={color}
      x="0"
      y="0"
      enableBackground="new 0 0 349.464 349.464"
      version="1.1"
      viewBox="0 0 349.464 349.464"
      xmlSpace="preserve"
    >
      <path d="M219.721 5.476c-10.465 0-20.638.962-30.385 2.766 8.874 4.003 16.515 9.247 22.968 15.749 2.459-.119 4.93-.2 7.417-.2 30.632 0 59.241 9.052 80.56 25.488 19.906 15.348 30.869 35.317 30.869 56.23 0 17.178-7.338 33.678-21.223 47.716l-4.201 4.248-.887 5.908-7.428 49.413-36.613-25.169-6.266-4.307-7.473 1.396a149.26 149.26 0 01-27.339 2.513c-7.4 0-14.679-.54-21.769-1.574-.752.136-1.499.273-2.269.399l-6.967 6.351c8.116 4.34 16.354 8.769 24.117 12.998 2.281.092 4.577.141 6.888.141a167.14 167.14 0 0030.702-2.824l49.97 34.352a8.176 8.176 0 007.89.76 8.177 8.177 0 004.83-6.281l9.836-65.443c16.633-16.817 26.516-37.816 26.516-60.595 0-55.248-58.086-100.035-129.743-100.035zM244.903 233.739c-10.519-7.253-47.238-26.872-62.919-35.25l-.328-.176a5.124 5.124 0 00-5.585.489c-8.227 6.456-17.232 10.806-26.766 12.929a5.134 5.134 0 00-3.68 3.188l-9.714 25.604-9.717-25.604a5.124 5.124 0 00-3.681-3.188c-9.533-2.123-18.538-6.473-26.767-12.93a5.126 5.126 0 00-5.583-.487c-15.504 8.283-52.702 28.315-63.202 35.396C9.197 245.677 1.441 288.966.065 297.522a5.137 5.137 0 00.229 2.528c.637 1.793 16.932 43.938 135.618 43.938 118.681 0 134.981-42.145 135.614-43.938.29-.812.367-1.68.23-2.528-3.734-23.229-11.426-53.151-26.853-63.783z"></path>
      <path d="M37.261 157.561H53.74c6.204 0 11.233-5.031 11.233-11.237V98.727c0-4.146-2.251-7.757-5.591-9.704 3.679-38.975 18.72-65.982 76.529-65.982 33.197 0 53.922 8.516 65.219 26.799 6.572 10.639 9.733 24.381 11.211 39.241-3.287 1.96-5.495 5.542-5.495 9.646v47.597c0 5.352 3.745 9.819 8.753 10.953-7.267 4.748-22.306 9.602-53.438 9.602a327.9 327.9 0 01-7.892-.098c-1.605-3.081-4.819-5.189-8.533-5.189h-13.734c-5.31 0-9.618 4.304-9.618 9.615 0 5.31 4.309 9.613 9.618 9.613h13.734a9.589 9.589 0 007.788-3.992c2.893.073 5.725.119 8.462.119 35.097 0 57.41-5.99 66.443-17.877.386-.506.735-1.01 1.058-1.509h5.075c6.204 0 11.233-5.031 11.233-11.237V98.727c0-6.204-5.029-11.235-11.233-11.235h-6.199c-1.742-17.955-5.608-33.24-13.557-46.105-14.311-23.163-40.119-34.423-78.896-34.423-38.779 0-64.586 11.26-78.898 34.423-7.949 12.864-11.813 28.148-13.558 46.105H37.26c-6.206 0-11.238 5.031-11.238 11.235v47.597c.001 6.206 5.033 11.237 11.239 11.237z"></path>
      <path d="M71.025 157.091c15.43 34.075 48.482 48.907 64.887 48.907 10.782 0 28.76-6.414 44.197-20.487-5.644.394-11.678.594-18.122.594-1.509 0-3.097-.013-4.797-.041a18.79 18.79 0 01-11.453 3.914h-13.734c-10.353 0-18.774-8.42-18.774-18.77 0-10.351 8.422-18.773 18.774-18.773h13.734c4.96 0 9.602 1.934 13.044 5.269 1.145.013 2.273.019 3.381.019 17.728 0 29.414-1.626 37.06-3.587a20.505 20.505 0 01-1.532-7.811V98.727c0-4.952 1.837-9.698 5.015-13.364-1.695-13.201-4.773-23.278-9.363-30.71-9.466-15.32-27.715-22.455-57.429-22.455-24.651 0-41.673 5.117-52.039 15.644-8.036 8.16-12.693 19.805-14.868 37.39 3.247 3.681 5.126 8.475 5.126 13.496v47.597a20.262 20.262 0 01-3.107 10.766z"></path>
    </svg>
    );
};

SupportIcon.defaultProps = {
    color: 'white',
    size: 20
};

export default SupportIcon;
