import React from "react";

const Icon10d = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <path
        fill="#feef7f"
        d="M296 184c0 61.855-50.145 112-112 112S72 245.855 72 184 122.145 72 184 72s112 50.145 112 112zm0 0"
      ></path>
      <path
        fill="#ea9a10"
        d="M184 296c61.855 0 112-50.145 112-112 0-33.492-14.707-63.547-38.008-84.07C214.293 120.297 184 164.605 184 216c-34.004 0-61.8 26.523-63.863 60.012C138.254 288.609 160.262 296 184 296zm0 0"
      ></path>
      <path
        fill="#b8ecff"
        d="M456 264c-1.379 0-2.734.07-4.082.184A128.158 128.158 0 00456 232c0-70.691-57.309-128-128-128s-128 57.309-128 128c-35.348 0-64 28.652-64 64s28.652 64 64 64h256c26.512 0 48-21.488 48-48s-21.488-48-48-48zm0 0"
      ></path>
      <path
        fill="#57bfe6"
        d="M456 264c-1.379 0-2.734.07-4.082.184A128.158 128.158 0 00456 232c0-70.691-57.309-128-128-128-4.047 0-8.05.195-12 .563 65.063 6.05 116 60.792 116 127.437 0 11.117-1.418 21.902-4.082 32.184A48.597 48.597 0 01432 264c26.512 0 48 21.488 48 48s-21.488 48-48 48h24c26.512 0 48-21.488 48-48s-21.488-48-48-48zm0 0"
      ></path>
      <g fill="#fff">
        <path d="M280 312.5h16v15h-16zm0 0M312 312.5h16v15h-16zm0 0M344 312.5h16v15h-16zm0 0"></path>
      </g>
      <path d="M184 127.5v-15c-39.426 0-71.5 32.074-71.5 71.5h15c0-31.152 25.348-56.5 56.5-56.5zm0 0"></path>
      <path d="M79.5 184c0-57.621 46.879-104.5 104.5-104.5 24.55 0 48.41 8.684 67.176 24.45l9.648-11.485C239.36 74.43 212.074 64.5 184 64.5c-65.895 0-119.5 53.605-119.5 119.5 0 36.836 16.61 71.059 45.57 93.895l9.29-11.782C94.026 246.145 79.5 216.215 79.5 184zm0 0"></path>
      <path d="M461.2 256.75a135.047 135.047 0 002.3-24.75c0-74.715-60.785-135.5-135.5-135.5-72.324 0-131.594 56.957-135.313 128.371-36.003 3.676-64.187 34.422-64.187 71.379 0 39.426 32.074 71.75 71.5 71.75h256c30.602 0 55.5-25.148 55.5-55.75 0-28.848-22.129-52.867-50.3-55.5zM456 353H200c-31.152 0-56.5-25.598-56.5-56.75 0-28.691 21.504-52.566 49.238-56.145a134.864 134.864 0 004.032 25.829l14.527-3.774a121.027 121.027 0 01-3.797-30.144c0-66.446 54.055-120.508 120.5-120.508 66.441 0 120.5 54.05 120.5 120.496 0 27.7-9.14 53.738-26.426 75.308l11.703 9.38c10.766-13.43 18.817-28.81 23.801-45.153 21.602.836 38.922 18.91 38.922 40.711 0 22.332-18.168 40.75-40.5 40.75zm0 0"></path>
      <path d="M240.5 232h15c0-39.977 32.523-72.5 72.5-72.5v-15c-48.246 0-87.5 39.254-87.5 87.5zm0 0M0 177h48v15H0zm0 0M176 0h15v48h-15zm0 0M314.695 42.695l10.606 10.606-40 40-10.606-10.606zm0 0M42.695 314.695l40-40 10.606 10.606-40 40zm0 0M53.305 42.695l40 40-10.61 10.606-40-40zm0 0M88 320h15v128H88zm0 0M128 368h15v112h-15zm0 0M168 496h15v16h-15zm0 0M168 384h15v96h-15zm0 0M208 384h15v128h-15zm0 0M248 384h15v80h-15zm0 0M288 384h15v128h-15zm0 0M328 384h15v112h-15zm0 0M368 464h15v16h-15zm0 0M368 384h15v64h-15zm0 0M408 384h15v128h-15zm0 0"></path>
    </svg>
    );
};

Icon10d.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon10d;