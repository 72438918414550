import React from 'react';

const SettingsIconOne = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      height={size}
      width={size}
      fill={color}
      enableBackground="new 0 0 512.002 512.002"
      version="1.1"
      viewBox="0 0 512.002 512.002"
      xmlSpace="preserve"
    >
      <path d="M491 1.314L334.53 68.867a14.896 14.896 0 00-4.702 3.179L21.929 384.003c-29.238 29.238-29.238 76.831 0 106.069s76.831 29.238 106.069 0l307.899-311.957a14.885 14.885 0 003.179-4.702l71.612-152.412C516.043 8.506 503.253-4.015 491 1.314zM138.604 437.045l-63.647-63.647 21.213-21.211 63.651 63.64-21.217 21.218zM412.636 158.94l-1.252 1.254c-14.614-5.198-28.07-13.438-39.133-24.501-11.063-11.074-19.301-24.527-24.5-39.133l1.252-1.252 49.951-21.412c4.7 6.85 9.805 13.434 15.732 19.362 5.931 5.931 12.513 11.034 19.362 15.732l-21.412 49.95zM481.256 332.76c-18.782-18.806-40.432-25.882-63.181-20.755l-35.212-38.434-42.429 42.43 31.133 34.354c5.859 5.859 5.859 15.352 0 21.211s-15.352 5.859-21.211 0l-31.133-34.354-42.422 42.422 35.205 38.426c-5.098 22.72 1.963 44.399 20.757 63.193 31.456 31.456 79.508 39.652 119.517 20.522 9.348-4.455 11.437-16.849 4.146-24.141l-38.804-38.804c-5.903-5.934-5.819-15.392 0-21.211 5.859-5.859 15.352-5.859 21.211 0l38.804 38.804c7.291 7.322 19.693 5.184 24.141-4.146 19.146-40.063 10.898-88.096-20.522-119.517zM158.026 136.816l33.916 30.693 42.429-42.429-34.377-31.155c5.112-22.734-1.963-44.399-20.757-63.179C147.817-.674 99.77-8.921 59.721 10.225c-9.348 4.455-11.437 16.849-4.146 24.141L94.379 73.17a14.97 14.97 0 01-.015 21.211c-5.639 5.668-15.498 5.668-21.196-.001L34.364 55.576c-7.46-7.429-19.761-5.045-24.141 4.146C-8.922 99.785-.675 147.818 30.746 179.238c18.794 18.809 40.43 25.84 63.193 20.757l34.37 31.147 42.422-42.422-33.916-30.693c-5.859-5.859-5.859-15.352 0-21.211s15.352-5.859 21.211 0z"></path>
    </svg>
    );
};

SettingsIconOne.defaultProps = {
    color: "white",
    size: 20
};

export default SettingsIconOne;
