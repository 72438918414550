import React from "react";

const Icon09d = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 1 511 512"
    >
      <path
        fill="#8bb4d9"
        d="M328.32 167.664c-1.379 0-2.738.07-4.086.184a128.429 128.429 0 004.086-32.215c0-70.762-57.363-128.125-128.125-128.125-70.761 0-128.125 57.363-128.125 128.125-35.382 0-64.062 28.68-64.062 64.062 0 35.38 28.68 64.063 64.062 64.063h256.25c26.535 0 48.047-21.512 48.047-48.047 0-26.535-21.512-48.047-48.047-48.047zm0 0"
      ></path>
      <path
        fill="#daedf7"
        d="M456.445 231.727c-1.379 0-2.738.07-4.086.183a128.226 128.226 0 004.086-32.215c0-70.761-57.363-128.125-128.125-128.125-70.761 0-128.125 57.364-128.125 128.125-35.379 0-64.062 28.68-64.062 64.063 0 35.379 28.683 64.062 64.062 64.062h256.25c26.535 0 48.047-21.511 48.047-48.047 0-26.535-21.512-48.046-48.047-48.046zm0 0"
      ></path>
      <g fill="#fff">
        <path d="M280.273 280.273h16.016v15.016h-16.016zm0 0M312.305 280.273h16.015v15.016h-16.015zm0 0M344.336 280.273h16.016v15.016h-16.016zm0 0"></path>
      </g>
      <path
        fill="#8bb4d9"
        d="M456.445 231.727c-1.379 0-2.738.07-4.086.183a128.226 128.226 0 004.086-32.215c0-70.761-57.363-128.125-128.125-128.125-4.05 0-8.054.196-12.011.563 65.129 6.058 116.113 60.851 116.113 127.562 0 11.13-1.418 21.922-4.086 32.215a48.691 48.691 0 014.086-.183c26.535 0 48.047 21.511 48.047 48.046 0 26.536-21.512 48.047-48.047 48.047h24.023c26.535 0 48.047-21.511 48.047-48.047 0-26.535-21.512-48.046-48.047-48.046zm0 0"
      ></path>
      <path d="M15.516 199.945c0-28.722 21.523-52.62 49.289-56.199a134.797 134.797 0 004.03 25.852l14.54-3.778a121.243 121.243 0 01-3.797-30.172c0-66.511 54.106-120.628 120.617-120.628 34.582 0 67.555 14.878 90.461 40.832l11.258-9.942C276.16 16.734 239.086 0 200.195 0 127.801 0 68.473 57.012 64.75 128.496 28.71 132.176.5 162.953.5 199.946c0 39.464 32.105 71.82 71.57 71.82h40.04V256.75H72.07c-31.187 0-56.554-25.621-56.554-56.805zm0 0"></path>
      <path d="M200.195 63.063V48.047c-48.297 0-87.586 39.289-87.586 87.586h15.016c0-40.016 32.555-72.57 72.57-72.57zm0 0M461.648 224.469a134.993 134.993 0 002.305-24.774c0-74.789-60.844-135.632-135.633-135.632-72.394 0-131.722 57.011-135.445 128.496-36.04 3.675-64.25 34.457-64.25 71.449 0 39.46 32.105 71.82 71.57 71.82h256.25c30.633 0 55.555-25.172 55.555-55.805 0-28.878-22.148-52.921-50.352-55.554zm-5.203 96.344h-256.25c-31.183 0-56.554-25.622-56.554-56.805 0-28.723 21.527-52.617 49.289-56.2a135.103 135.103 0 004.03 25.852l14.544-3.773a121.118 121.118 0 01-3.8-30.176c0-66.512 54.108-120.629 120.616-120.629 66.508 0 120.618 54.106 120.618 120.617 0 27.727-9.145 53.793-26.45 75.38l11.715 9.39c10.777-13.446 18.832-28.836 23.824-45.196 21.625.836 38.957 18.926 38.957 40.75 0 22.352-18.183 40.79-40.539 40.79zm0 0"></path>
      <path d="M240.734 199.695h15.016c0-40.015 32.555-72.57 72.57-72.57v-15.016c-48.297 0-87.586 39.29-87.586 87.586zm0 0M88.086 287.781h15.012v160.156H88.086zm0 0M128.125 335.828h15.012v144.14h-15.012zm0 0M168.164 495.984h15.016V512h-15.016zm0 0M168.164 351.844h15.016v128.125h-15.016zm0 0M208.203 351.844h15.016V512h-15.016zm0 0M248.242 351.844h15.016v112.11h-15.016zm0 0M288.281 351.844h15.016V512H288.28zm0 0M328.32 351.844h15.016v144.14H328.32zm0 0M368.36 463.953h15.015v16.016h-15.016zm0 0M368.36 351.844h15.015v96.094h-15.016zm0 0M408.398 351.844h15.016V512h-15.016zm0 0"></path>
    </svg>
    );
};

Icon09d.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon09d;