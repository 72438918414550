import React from "react";

const BodyEditorIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 60 60"
    >
      <path
        fill="#9e9ceb"
        d="M54.07 3.08L51.34 11l-5.173-1-4.777-4.77a1.953 1.953 0 01.94-.7l9.74-3.44a1.57 1.57 0 012 1.99z"
      ></path>
      <path
        fill="#8486d0"
        d="M57.93 14.82L41 15V6.41a1.966 1.966 0 01.39-1.18L51.34 11zM39.65 48.97a1.932 1.932 0 01-.66.61l-16.17 9.25a1.27 1.27 0 01-1.75-1.7l4.95-9.3L34 47z"
      ></path>
      <path
        fill="#9e9ceb"
        d="M40 38v9.84a2.014 2.014 0 01-.35 1.13l-13.63-1.14-20.19-1.68a1.951 1.951 0 01-.92-.32z"
      ></path>
      <path
        fill="#8486d0"
        d="M44.17 38l-4.17.83-35.09 7A1.975 1.975 0 014 44.16V36z"
      ></path>
      <path
        fill="#9e9ceb"
        d="M57 38H3a2 2 0 01-2-2V11a2 2 0 012.206-1.989l54 5.586A2 2 0 0159 16.586V36a2 2 0 01-2 2z"
      ></path>
      <path
        fill="#373737"
        d="M58.349 13.907l-5.795-3.36L55.018 3.4A2.571 2.571 0 0051.737.146l-9.744 3.443A3.007 3.007 0 0040 6.41v5.4L12.1 8.926a1 1 0 00-.206 1.99L57.1 15.592a1 1 0 01.9.994V36a1 1 0 01-1 1H3a1 1 0 01-1-1V11a.988.988 0 01.33-.742.962.962 0 01.774-.252l4.793.5a1.018 1.018 0 001.1-.893 1 1 0 00-.892-1.1l-4.794-.5A3 3 0 000 11v25a3 3 0 003 3v5.161a3.009 3.009 0 002.747 2.987l18.673 1.558-4.231 7.948a2.273 2.273 0 002 3.343 2.284 2.284 0 001.131-.3l16.159-9.242a2.911 2.911 0 001-.919 3.029 3.029 0 00.525-1.7v-8.184L44.266 39H57a3 3 0 003-3V16.586a2.992 2.992 0 00-1.651-2.679zM34.046 39L5.2 44.754a.98.98 0 01-.2-.593V39zM22.327 57.957a.25.25 0 01-.327-.041.244.244 0 01-.048-.319l4.636-8.711 10.121.844zM39 47.839c0 .025-.01.05-.012.075L11.2 45.6 39 40.051zm13.4-45.8a.547.547 0 01.583.133.557.557 0 01.142.587l-2.334 6.766-7.419-4.3zM42 6.741l11.079 6.423L42 12.018z"
      ></path>
    </svg>
    );
};

BodyEditorIcon.defaultProps = {
    color: 'white',
    size: 20
};

export default BodyEditorIcon;