import React from 'react';

const RemoveUser = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      width={size}
      height={size}
      fill={color}
      enableBackground="new 0 0 368.373 368.373"
      version="1.1"
      viewBox="0 0 368.373 368.373"
      xmlSpace="preserve"
    >
      <g>
        <path d="M295.192 210.117c-5.857-5.857-15.355-5.857-21.213 0l-10.607 10.606-10.606-10.606c-5.857-5.857-15.355-5.857-21.213 0-5.857 5.857-5.857 15.355 0 21.213l10.606 10.606-10.606 10.606c-5.857 5.857-5.857 15.356 0 21.213a14.954 14.954 0 0010.606 4.394 14.95 14.95 0 0010.606-4.394l10.606-10.606 10.607 10.606a14.952 14.952 0 0010.606 4.394c3.839 0 7.678-1.465 10.607-4.394 5.857-5.857 5.857-15.355 0-21.213l-10.607-10.606 10.607-10.606c5.859-5.857 5.859-15.355.001-21.213z"></path>
        <path d="M263.373 136.936c-34.485 0-65.14 16.713-84.293 42.463a115.053 115.053 0 00-23.707-2.463c-8.643 0-17.064.965-25.165 2.781-38.121 8.543-69.149 36.066-82.606 72.092a114.449 114.449 0 00-7.229 40.127c0 8.284 6.716 15 15 15h125.596c19.246 24.348 49.03 40 82.404 40 57.898 0 105-47.103 105-105s-47.102-105-105-105zm0 180a74.507 74.507 0 01-37.344-10c-22.48-12.967-37.656-37.236-37.656-65 0-20.01 7.892-38.199 20.708-51.662 13.67-14.359 32.946-23.338 54.292-23.338 41.355 0 75 33.645 75 75s-33.645 75-75 75z"></path>
        <path d="M32.622 84.186c0 23.666 18.367 43.109 41.594 44.857-7.382-13.302-11.594-28.596-11.594-44.857s4.212-31.556 11.594-44.857C50.989 41.077 32.622 60.52 32.622 84.186z"></path>
        <path d="M93.489 160.825a77.775 77.775 0 00-15.867-1.639C34.821 159.186 0 194.008 0 236.809c0 8.284 6.716 15 15 15h1.025c11.602-40.228 40.193-73.322 77.464-90.984z"></path>
        <path d="M155.373 21.436c-21.093 0-39.774 10.473-51.157 26.478-7.289 10.25-11.594 22.764-11.594 36.271s4.305 26.021 11.594 36.271c11.383 16.006 30.064 26.479 51.157 26.479 34.601 0 62.75-28.149 62.75-62.75s-28.149-62.749-62.75-62.749z"></path>
      </g>
    </svg>
    );
};

RemoveUser.defaultProps = {
    color: "black",
    size: 20
};

export default RemoveUser;