import React from 'react';

const CustomizeIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 57 56"
    >
      <g fill="none" fillRule="evenodd">
        <g fill={color} fillRule="nonzero" transform="translate(0 -1)">
          <path d="M18.648 52.628c-3.265 3.315-8.882 4.844-15.022 4.258a1 1 0 01-.738-1.558 4.448 4.448 0 00.762-4.189c-1.932-5.9-1.525-8.971 1.614-12.168a9.346 9.346 0 016.931-2.79l.974.991a11.646 11.646 0 00-7 5.274 1 1 0 101.664 1.108c2.6-3.9 5.574-4.547 7.081-4.609l.856.871-5.476 5.477a1 1 0 101.414 1.414l5.465-5.465 1.01 1.028c-.089 2.4-1.138 6.391-6.588 8.816a1 1 0 00.81 1.828c5.26-2.341 7.042-5.977 7.576-8.814l1.419 1.439a9.71 9.71 0 01-2.752 7.089zm6.473-8.468a1.59 1.59 0 01-2.274 0L17.9 39.126l-.009-.013-.013-.009-1.811-1.842-.019-.019-2.451-2.492a1.69 1.69 0 010-2.361 1.585 1.585 0 012.274 0l9.25 9.41a1.694 1.694 0 010 2.36zm.971-4.227l-8.357-8.5a27.4 27.4 0 014.421-7.706l11.506 11.7a26.612 26.612 0 01-7.57 4.506zm15.421-1.806c.772.772 1.9 1.07 2.952.78a9.291 9.291 0 018.918 2.4A8.862 8.862 0 0156 47.386a1 1 0 01-1.71.7l-3.122-3.128a3.142 3.142 0 00-2.583-.864l-2.527.369a3.037 3.037 0 00-2.6 2.6l-.37 2.537a3.142 3.142 0 00.876 2.574l3.118 3.113a1 1 0 01-.7 1.709 8.857 8.857 0 01-6.078-2.617 9.289 9.289 0 01-2.4-8.906 3.05 3.05 0 00-.777-2.964l-4.062-4.062a20.265 20.265 0 002.735-2.3c.043-.043.7-.771 1.757-1.982zM54.546 1a1.5 1.5 0 011.11.343c3.271 3.327-13.536 25.2-20.578 32.674l-11.53-11.724C28.917 17.141 48.142 1 54.546 1zM0 10.614a1 1 0 011.709-.7l3.116 3.116a3 3 0 002.585.87l2.542-.36a3.046 3.046 0 002.588-2.589l.36-2.525a2.985 2.985 0 00-.859-2.593L8.916 2.706A1 1 0 019.614 1a8.847 8.847 0 016.08 2.621 9.267 9.267 0 012.4 8.923 3.03 3.03 0 00.779 2.945l4.367 4.367a52.599 52.599 0 00-1.82 1.726 20.63 20.63 0 00-2.336 2.89l-4.59-4.591a3.041 3.041 0 00-2.951-.785 9.273 9.273 0 01-8.926-2.4A8.853 8.853 0 010 10.614z"></path>
        </g>
      </g>
    </svg>
    );
};

CustomizeIcon.defaultProps = {
    color: 'black',
    size: 20
};

export default CustomizeIcon;