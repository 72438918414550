import React from 'react';

const SaveStudioIcon = ({color, height, width}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      clipRule="evenodd"
      viewBox="0 0 2970 1344"
      height={height}
      width={width}
    >
      <g transform="translate(-1962.59 -10680.3)">
        <path
          fill={color}
          d="M6727.66 10768.8c0-48.9-44.13-88.5-98.57-88.5H2060.64c-26.01 0-50.94 9.2-69.33 25.7-18.39 16.6-28.72 38.9-28.72 62.3v1760.5c0 23.4 10.33 45.7 28.72 62.3 18.39 16.5 43.32 25.7 69.33 25.7h4568.45c54.44 0 98.57-39.6 98.57-88.5v-1759.5z"
          transform="matrix(.62308 0 0 .694 739.746 3268.09)"
        ></path>
        <g transform="translate(3479.17 11329.3) scale(1.39253) translate(-256 -256)">
          <clipPath id="_clip1">
            <path d="M0 0H512V512H0z"></path>
          </clipPath>
          <g clipPath="url(#_clip1)">
            <g fillRule="nonzero">
              <path
                fill="#EAECF0"
                d="M20.819 33.973v444.054c0 14.621 11.852 26.473 26.473 26.473h338.027c14.621 0 26.473-11.852 26.473-26.473V124.25L295.042 7.5H47.292c-14.621 0-26.473 11.852-26.473 26.473z"
              ></path>
              <path
                fill="#D3D7DE"
                d="M320.469 32.63v109.832c0 11.056 8.962 20.018 20.018 20.018h18.602V504.5h26.21c14.632 0 26.494-11.862 26.494-26.494V123.954L320.469 32.63z"
              ></path>
              <path
                fill="#DF705D"
                d="M295.042 7.5l116.75 116.75h-96.748c-11.047 0-20.003-8.955-20.003-20.003V7.5h.001z"
              ></path>
            </g>
            <path
              fill="#676E84"
              fillRule="nonzero"
              d="M411.782 250.102H252.219c-12.224 0-22.133 9.909-22.133 22.133v210.119c0 12.224 9.909 22.133 22.133 22.133h216.829c12.224 0 22.133-9.909 22.133-22.133V329.501a41.792 41.792 0 00-12.239-29.549l-37.611-37.611a41.792 41.792 0 00-29.549-12.239z"
            ></path>
            <path
              fill="#4A4E67"
              fillRule="nonzero"
              d="M478.942 299.952l-37.611-37.611a41.788 41.788 0 00-29.549-12.239h-35.394l49.85 49.85a41.788 41.788 0 0112.239 29.549v174.985h30.571c12.224 0 22.133-9.909 22.133-22.133V329.501a41.792 41.792 0 00-12.239-29.549z"
            ></path>
            <path
              fill="#EAECF0"
              fillRule="nonzero"
              d="M270.448 504.486H450.24v-80.693c0-7.562-6.13-13.692-13.692-13.692H284.14c-7.562 0-13.692 6.13-13.692 13.692v80.693z"
            ></path>
            <path
              fill="#D3D7DE"
              fillRule="nonzero"
              d="M436.548 410.101h-39.012v94.385h52.703v-80.693c.001-7.562-6.129-13.692-13.691-13.692z"
            ></path>
            <path
              fill="#759CE7"
              fillRule="nonzero"
              d="M284.14 348.564h105.753c7.562 0 13.692-6.13 13.692-13.692v-34.159c0-7.562-6.13-13.692-13.692-13.692H284.14c-7.562 0-13.692 6.13-13.692 13.692v34.159c0 7.562 6.13 13.692 13.692 13.692z"
            ></path>
            <path
              fill="#5881CC"
              fillRule="nonzero"
              d="M389.893 287.022h-39.012v61.543h39.012c7.562 0 13.692-6.13 13.692-13.692v-34.159c0-7.562-6.13-13.692-13.692-13.692z"
            ></path>
            <g fillRule="nonzero">
              <path d="M69.318 67.374a7.5 7.5 0 00-7.5 7.5 7.5 7.5 0 007.5 7.5h151.127a7.5 7.5 0 007.5-7.5 7.5 7.5 0 00-7.5-7.5H69.318zM227.945 124.25a7.5 7.5 0 00-7.5-7.5H69.318a7.5 7.5 0 00-7.5 7.5 7.5 7.5 0 007.5 7.5h151.127a7.5 7.5 0 007.5-7.5zM424.389 434.63H296.3a7.499 7.499 0 00-7.5 7.5c0 4.143 3.357 7.5 7.5 7.5h128.089c4.143 0 7.5-3.357 7.5-7.5a7.5 7.5 0 00-7.5-7.5zM424.389 467.152H296.3a7.499 7.499 0 00-7.5 7.5c0 4.143 3.357 7.5 7.5 7.5h128.089c4.143 0 7.5-3.357 7.5-7.5a7.5 7.5 0 00-7.5-7.5z"></path>
              <path d="M484.247 294.483l-37.61-37.643c-9.311-9.316-21.688-14.447-34.854-14.447H252.219c-16.339 0-29.633 13.302-29.633 29.651v210.29c0 16.35 13.293 29.651 29.633 29.651h216.829c16.34 0 29.633-13.302 29.633-29.651V329.357c0-13.172-5.126-25.558-14.434-34.874zM442.74 496.986H277.948v-73.193a6.199 6.199 0 016.191-6.192h152.409a6.199 6.199 0 016.191 6.192v73.193h.001zm40.941-14.651c0 8.079-6.564 14.651-14.633 14.651H457.74v-73.193c0-11.686-9.507-21.192-21.191-21.192H284.14c-11.685 0-21.191 9.507-21.191 21.192v73.193H252.22c-8.068 0-14.633-6.572-14.633-14.651v-210.29c0-8.079 6.564-14.651 14.633-14.651h159.563c9.157 0 17.768 3.569 24.243 10.05l37.61 37.642c6.478 6.483 10.045 15.104 10.045 24.272v152.977z"></path>
              <path d="M389.894 279.521H284.14c-11.685 0-21.191 9.507-21.191 21.191v34.16c0 11.685 9.507 21.191 21.191 21.191h105.754c11.685 0 21.191-9.507 21.191-21.191v-34.16c0-11.684-9.507-21.191-21.191-21.191zm6.191 55.352a6.198 6.198 0 01-6.191 6.191H284.14a6.198 6.198 0 01-6.191-6.191v-34.16a6.198 6.198 0 016.191-6.191h105.754a6.198 6.198 0 016.191 6.191v34.16z"></path>
              <path d="M195.19 497H47.292c-10.462 0-18.973-8.512-18.973-18.974V33.974C28.319 23.512 36.83 15 47.292 15h240.25v89.247c0 15.165 12.338 27.503 27.503 27.503h89.247v83.708c0 4.143 3.357 7.5 7.5 7.5s7.5-3.357 7.5-7.5V124.25c0-1.989-.79-3.896-2.196-5.304L300.346 2.196A7.504 7.504 0 00295.042 0H47.292C28.559 0 13.319 15.24 13.319 33.974v444.053c0 18.733 15.24 33.974 33.973 33.974H195.19a7.5 7.5 0 000-15.001zm107.352-392.753V25.606l91.144 91.144h-78.641c-6.894 0-12.503-5.608-12.503-12.503z"></path>
            </g>
          </g>
        </g>
      </g>
    </svg>
    );
};

SaveStudioIcon.defaultProps = {
    color: "#2F3E46",
    size: 18,
    height: 115,
    width: 248
};

export default SaveStudioIcon;