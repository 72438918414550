import React from 'react';

const UploadEditorIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 100 100"
    >
      <path
        d="M61.822 68.056h9.043v5.463h-41.73v-5.463h9.043a4.738 4.738 0 004.622 3.732h14.4a4.738 4.738 0 004.622-3.732zM94.72 35.35v42.69a8.369 8.369 0 01-8.36 8.36H13.64a8.369 8.369 0 01-8.36-8.36V21.96a8.369 8.369 0 018.36-8.36h38.69a8.384 8.384 0 016.914 3.644l6.61 9.7a.532.532 0 01.021.05H86.36a8.369 8.369 0 018.36 8.356zM37.576 15.6a8.308 8.308 0 011.49 1.647l6.642 9.743h17.76l-5.874-8.616A6.386 6.386 0 0052.33 15.6zm8.964 37.255v8.91a1 1 0 001 1h4.92a1 1 0 001-1v-8.91h4.04a1 1 0 00.866-1.5l-7.5-12.979a1.04 1.04 0 00-1.732 0l-7.5 12.979a1 1 0 00.866 1.5zm26.325 14.2a1 1 0 00-1-1h-43.73a1 1 0 00-1 1v7.463a1 1 0 001 1h43.73a1 1 0 001-1zm16.15-3.367a1 1 0 00-2 0v10.494a1 1 0 002 0zm0-24.477a1 1 0 00-2 0v19.7a1 1 0 002 0z"
        data-name="20"
      ></path>
    </svg>
    );
};

UploadEditorIcon.defaultProps = {
    color: 'white',
    size: 20
};

export default UploadEditorIcon;