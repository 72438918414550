import React from 'react';

const TopicsIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      height={size}
      width={size}
      fill={color}
      enableBackground="new 0 0 25.974 25.974"
      version="1.1"
      viewBox="0 0 25.974 25.974"
      xmlSpace="preserve"
    >
      <path
        fill="#030104"
        d="M8.973 11.146v9.558a.998.998 0 01-.668.943c-.566.199-1.249.304-1.976.304-2.091 0-4.34-.858-4.34-2.742V6.979c-.047-.84.246-2.324 1.653-3.127.653-.373 4.082-2.578 5.804-3.692a1.002 1.002 0 011.543.84v1.448a1 1 0 01-1.938.347c-1.556 1-3.848 2.468-4.417 2.793-.512.293-.623.831-.645 1.123 0 .328.071.586.199.727.355.387 1.538.146 2.829-.629 1.24-.744 7.356-4.816 7.417-4.857a1 1 0 011.555.833v.114c0 .334-.167.646-.444.832 0 0-4.246 2.834-4.622 3.075-1.439.926-1.95 2.062-1.95 4.34zM23.989 6.32v12.982a1 1 0 01-.468.846s-5.83 4.528-7.013 5.247c-.622.379-1.414.579-2.289.579-2.079 0-4.23-1.125-4.23-3.006V10.559c0-.004.003-.008.003-.013.02-.732.191-1.777 1.552-2.781.816-.602 5.676-3.916 5.882-4.056a.999.999 0 011.563.826v1.448a1 1 0 01-1.92.392c-1.551 1.061-3.828 2.624-4.338 2.999-.644.476-.73.79-.741 1.193.002.299.083.527.247.677.51.467 1.883.271 3.237-.541 1.002-.602 5.32-3.936 6.898-5.17.303-.234.711-.277 1.055-.11s.562.514.562.897zm-2 4.456l-5 3.879v2l5-3.879v-2z"
      ></path>
    </svg>
    );
};

TopicsIcon.defaultProps = {
    color: "white",
    size: 20
};

export default TopicsIcon;