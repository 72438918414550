import React from 'react';

const ExploreIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fillRule="evenodd"
      clipRule="evenodd"
      imageRendering="optimizeQuality"
      shapeRendering="geometricPrecision"
      textRendering="geometricPrecision"
      viewBox="0 0 1707 1707"
    >
      <g>
        <g>
          <path
            fill="#cfd8dd"
            d="M925 361c-3 0-6-1-8-2-6-2-10-7-11-13l-1-3H802l-1 3c-2 6-6 11-11 13-5 3-12 3-17 0-66-31-108-98-108-170C665 85 749 0 853 0s189 85 189 189c0 72-43 139-108 170-3 1-6 2-9 2zm-139-58h135c6 0 12 4 16 8 40-27 65-73 65-122 0-82-67-149-149-149s-148 67-148 149c0 49 25 95 65 122 4-4 9-8 16-8z"
          ></path>
          <path
            fill="#273238"
            d="M853 1707c-357 0-648-291-648-649 0-357 291-648 648-648 358 0 649 291 649 648 0 358-291 649-649 649z"
          ></path>
          <path
            fill="#fff"
            d="M853 613c-245 0-445 200-445 445 0 246 200 446 445 446 246 0 446-200 446-446 0-245-200-445-446-445z"
          ></path>
          <path
            fill="#ee534f"
            d="M958 1045h-3c-7-1-14-6-16-14-9-27-31-49-59-58-7-2-13-9-14-17-1-7 3-15 9-19l200-128c8-5 18-4 25 3 6 7 7 17 2 25l-127 199c-4 6-10 9-17 9z"
          ></path>
          <path
            fill="#36474f"
            d="M621 1311c-5 0-10-2-14-6-7-7-8-17-3-25l128-200c4-6 12-10 19-9 8 1 15 7 17 14 9 28 31 50 58 59 8 2 13 9 14 16 1 8-2 16-9 20l-199 127c-3 3-7 4-11 4z"
          ></path>
          <path
            fill="#d64b47"
            d="M958 1045h-3c-7-1-14-6-16-14-9-27-31-49-59-58-7-2-13-9-14-17-1-7 3-15 9-19l37-23c39 15 70 47 86 86l-23 36c-4 6-10 9-17 9z"
          ></path>
          <path
            fill="#314047"
            d="M709 1117l23-37c4-6 12-10 19-9 8 1 15 7 17 14 9 28 31 50 58 59 8 2 13 9 14 16 1 8-2 16-9 20l-36 23c-39-16-71-47-86-86z"
          ></path>
          <g fill="#273238">
            <path d="M853 782c-11 0-20-9-20-20V593c0-11 9-20 20-20s20 9 20 20v169c0 11-9 20-20 20zM1319 1078h-169c-11 0-20-9-20-20s9-20 20-20h169c11 0 20 9 20 20s-9 20-20 20zM853 1544c-11 0-20-9-20-20v-169c0-11 9-20 20-20s20 9 20 20v169c0 11-9 20-20 20zM557 1078H388c-11 0-20-9-20-20s9-20 20-20h169c11 0 20 9 20 20s-9 20-20 20z"></path>
          </g>
          <path
            fill="#20292d"
            d="M949 457h-3c-61-9-124-9-186 0-6 1-13-1-17-6-5-5-7-12-5-19l28-113c3-9 11-16 20-16h135c9 0 17 7 19 16l29 113c1 7 0 14-5 19-4 4-9 6-15 6z"
          ></path>
          <path
            fill="#cfd8dd"
            d="M853 1188c-71 0-129-58-129-130 0-71 58-129 129-129 72 0 130 58 130 129 0 72-58 130-130 130z"
          ></path>
          <path
            fill="#e6e6e6"
            d="M873 661c10-1 20-1 30-1 106 0 203 37 280 99-78-85-187-140-310-145zM833 666v-52c-229 10-414 195-424 424h54c29-191 180-342 370-372zM458 1105c0-9 0-18 1-27h-50c5 132 69 249 165 327-72-79-116-185-116-300z"
          ></path>
        </g>
      </g>
    </svg>
    );
};

ExploreIcon.defaultProps = {
    color: "white",
    size: 18
};

export default ExploreIcon;