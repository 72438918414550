import React from 'react';

const EmptyBoxIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 511 511"
    >
      <path d="M487.5 98.697c-12.958 0-23.5 10.542-23.5 23.5s10.542 23.5 23.5 23.5 23.5-10.542 23.5-23.5-10.542-23.5-23.5-23.5zm0 32c-4.687 0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5 8.5 3.813 8.5 8.5-3.813 8.5-8.5 8.5zM23.5 370.697c-12.958 0-23.5 10.542-23.5 23.5s10.542 23.5 23.5 23.5 23.5-10.542 23.5-23.5-10.542-23.5-23.5-23.5zm0 32c-4.687 0-8.5-3.813-8.5-8.5s3.813-8.5 8.5-8.5 8.5 3.813 8.5 8.5-3.813 8.5-8.5 8.5zM416 90.197h15v-16.5h16.5v-15H431v-16.5h-15v16.5h-16.5v15H416zM119 402.197h-15v16.5H87.5v15H104v16.5h15v-16.5h16.5v-15H119zM415 106.697H203.514l-32-48H48v303h111.5v-15H65.106l44.25-177h336.539l-44.25 177H375.5v15h37.855l51.75-207H415zm-251.514-33l22 33H63v-33zm-65.841 81L63 293.273V121.697h337v33z"></path>
      <path d="M255.5 218.697c-39.425 0-71.5 32.075-71.5 71.5s32.075 71.5 71.5 71.5 71.5-32.075 71.5-71.5-32.075-71.5-71.5-71.5zm0 128c-31.154 0-56.5-25.346-56.5-56.5s25.346-56.5 56.5-56.5 56.5 25.346 56.5 56.5-25.346 56.5-56.5 56.5z"></path>
      <path d="M359 290.197c0-57.07-46.43-103.5-103.5-103.5S152 233.126 152 290.197s46.43 103.5 103.5 103.5c19.685 0 38.104-5.526 53.788-15.105l90.212 90.212 34.606-34.606-90.212-90.212C353.474 328.3 359 309.882 359 290.197zm-192 0c0-48.799 39.701-88.5 88.5-88.5s88.5 39.701 88.5 88.5-39.701 88.5-88.5 88.5-88.5-39.701-88.5-88.5zM399.5 447.59l-53.393-53.393 13.393-13.393 53.394 53.393zm-50.607-77.393L335.5 383.59l-13.853-13.853a104.478 104.478 0 0013.393-13.393z"></path>
      <path d="M256.601 250.722c-6.491-.297-12.643 2.002-17.33 6.479A23.613 23.613 0 00232 274.197h15c0-2.345.935-4.528 2.631-6.148a8.438 8.438 0 016.28-2.342c4.259.196 7.883 3.82 8.079 8.079a8.506 8.506 0 01-3.52 7.308c-7.808 5.646-12.47 15.031-12.47 25.103h15c0-5.273 2.34-10.114 6.26-12.948a23.463 23.463 0 009.715-20.152c-.552-11.995-10.38-21.824-22.374-22.375zM248 314.197h15v16h-15zM79.5 82.697h16v15h-16zM111.5 82.697h16v15h-16zM143.5 82.697h16v15h-16z"></path>
    </svg>
    );
};

EmptyBoxIcon.defaultProps = {
    color: "white",
    size: 18
};

export default EmptyBoxIcon;