import React from 'react';

const StarIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 512 512"
    >
      <path d="M470.04 164.297a14.999 14.999 0 00-10.974-9.999l-124.872-28.081-65.293-110.081a15 15 0 00-25.802 0l-65.293 110.082-124.871 28.081a14.999 14.999 0 00-7.973 24.539l84.517 96.115-11.882 127.436a15.002 15.002 0 0020.875 15.167L256 366.876l117.528 50.68a14.99 14.99 0 0014.755-1.639 15 15 0 006.119-13.528L382.52 274.953l84.518-96.115a14.998 14.998 0 003.002-14.541zm-86.893 41.799l-47.232 53.713 6.64 71.218a15.002 15.002 0 01-20.874 15.167L256 317.87l-65.68 28.323a15.001 15.001 0 01-20.875-15.167l6.64-71.218-47.232-53.713a14.998 14.998 0 017.973-24.539l69.784-15.694 36.489-61.519a15 15 0 0125.802 0l36.489 61.519 69.784 15.694a15 15 0 017.973 24.54z"></path>
      <path d="M282.887 186.731L256 141.4l-26.887 45.331a14.998 14.998 0 01-9.61 6.982l-51.422 11.564 34.804 39.579a15.006 15.006 0 013.671 11.298l-4.893 52.478 48.398-20.87a15.002 15.002 0 0111.878 0l48.398 20.87-4.892-52.478a15.002 15.002 0 013.671-11.298l34.804-39.579-51.422-11.564a15 15 0 01-9.611-6.982z"></path>
      <g>
        <path d="M256 503.212c-8.284 0-15-6.716-15-15v-46.069c0-8.284 6.716-15 15-15s15 6.716 15 15v46.069c0 8.284-6.716 15-15 15zM15.003 328.122c-6.209 0-12.014-3.884-14.168-10.079-2.72-7.825 1.42-16.373 9.244-19.092l42.89-14.906c7.824-2.72 16.374 1.418 19.093 9.244 2.72 7.825-1.42 16.373-9.244 19.092l-42.89 14.906a14.985 14.985 0 01-4.925.835zM496.997 328.122a15 15 0 01-4.926-.835l-42.89-14.906c-7.824-2.719-11.964-11.267-9.244-19.092 2.721-7.826 11.266-11.965 19.093-9.244l42.89 14.906c7.824 2.719 11.964 11.267 9.244 19.092-2.153 6.195-7.959 10.079-14.167 10.079zM378.421 80.327c-2.632 0-5.297-.693-7.717-2.149-7.099-4.27-9.392-13.486-5.122-20.585L388.49 19.51c4.271-7.098 13.486-9.392 20.585-5.122s9.392 13.486 5.122 20.585l-22.908 38.083c-2.815 4.68-7.779 7.271-12.868 7.271zM133.579 80.327c-5.091 0-10.053-2.591-12.868-7.27L97.803 34.974c-4.271-7.099-1.978-16.315 5.122-20.585 7.099-4.272 16.316-1.976 20.585 5.122l22.908 38.083c4.27 7.099 1.978 16.315-5.122 20.585a14.933 14.933 0 01-7.717 2.148z"></path>
      </g>
    </svg>
    );
};

StarIcon.defaultProps = {
    color: "white",
    size: 20
};

export default StarIcon;