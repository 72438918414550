import React from 'react';

const TwitterIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 49.652 49.652"
    >
      <path d="M24.826 0C11.137 0 0 11.137 0 24.826c0 13.688 11.137 24.826 24.826 24.826 13.688 0 24.826-11.138 24.826-24.826C49.652 11.137 38.516 0 24.826 0zm11.075 19.144c.011.246.017.494.017.742 0 7.551-5.746 16.255-16.259 16.255-3.227 0-6.231-.943-8.759-2.565.447.053.902.08 1.363.08 2.678 0 5.141-.914 7.097-2.446a5.72 5.72 0 01-5.338-3.969 5.76 5.76 0 002.58-.096 5.715 5.715 0 01-4.583-5.603l.001-.072a5.69 5.69 0 002.587.714 5.71 5.71 0 01-2.541-4.755c0-1.048.281-2.03.773-2.874a16.225 16.225 0 0011.777 5.972 5.707 5.707 0 01-.147-1.303 5.714 5.714 0 019.884-3.91 11.406 11.406 0 003.63-1.387 5.74 5.74 0 01-2.516 3.162 11.36 11.36 0 003.282-.899 11.494 11.494 0 01-2.848 2.954z"></path>
    </svg>
    );
};

TwitterIcon.defaultProps = {
    color: 'black',
    size: 20
};

export default TwitterIcon;