import React from 'react';

const PasswordTwoIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 511.38 511.38"
    >
      <path
        fill="#685e68"
        d="M358.237 199.506h-49.142v-69.618c0-14.214-5.54-27.588-15.598-37.657-10.051-10.04-23.425-15.58-37.639-15.58-29.355 0-53.237 23.882-53.237 53.237v69.618h-49.142v-69.618c0-56.452 45.928-102.38 102.38-102.38 27.331 0 53.038 10.644 72.388 29.973 19.347 19.368 29.992 45.076 29.992 72.406v69.619z"
      ></path>
      <path
        fill="#554e56"
        d="M270.905 28.595c-49.39 7.26-87.43 49.92-87.43 101.29v69.62h-30v-69.62c0-56.45 45.93-102.38 102.38-102.38 5.09 0 10.11.37 15.05 1.09z"
      ></path>
      <path
        fill="#fe646f"
        d="M366.915 419.008H144.8c-8.778 0-15.894-7.116-15.894-15.894V197.381c0-8.778 7.116-15.894 15.894-15.894h222.114c8.778 0 15.894 7.116 15.894 15.894v205.734c0 8.777-7.116 15.893-15.893 15.893z"
      ></path>
      <path
        fill="#554e56"
        d="M281.377 360.618h-51.04c-2.84 0-5.04-2.46-4.73-5.28l7.699-68.484a6.043 6.043 0 00-.945-3.949c-3.087-4.788-4.752-10.587-4.383-16.802.828-13.939 12.157-25.304 26.094-26.17 16.24-1.009 29.715 11.854 29.715 27.874a27.762 27.762 0 01-4.43 15.092 6.056 6.056 0 00-.949 3.955l7.699 68.483a4.755 4.755 0 01-4.73 5.281z"
      ></path>
      <path
        fill="#fd4755"
        d="M174.805 419.005h-30c-8.78 0-15.9-7.11-15.9-15.89v-205.73c0-8.78 7.12-15.9 15.9-15.9h30c-8.78 0-15.9 7.12-15.9 15.9v205.73c0 8.78 7.12 15.89 15.9 15.89z"
      ></path>
      <path
        fill="#a2f4c1"
        d="M479.839 484.428H31.875c-13.368 0-24.205-10.837-24.205-24.205V353.714c0-13.368 10.837-24.205 24.205-24.205h447.964c13.368 0 24.205 10.837 24.205 24.205v106.509c.001 13.367-10.836 24.205-24.205 24.205z"
      ></path>
      <path
        fill="#78d0b1"
        d="M61.875 484.425h-30c-13.37 0-24.21-10.83-24.21-24.2v-106.51c0-13.37 10.84-24.21 24.21-24.21h30c-13.37 0-24.21 10.84-24.21 24.21v106.51c0 13.37 10.84 24.2 24.21 24.2z"
      ></path>
      <g>
        <path d="M83.603 455.38a7.5 7.5 0 007.5-7.5v-23.985l20.771 11.992a7.499 7.499 0 0010.245-2.745 7.5 7.5 0 00-2.745-10.245l-20.771-11.992 20.771-11.992a7.5 7.5 0 00-7.5-12.99l-20.771 11.992V373.93a7.5 7.5 0 00-15 0v23.984l-20.771-11.992a7.5 7.5 0 00-7.5 12.99l20.771 11.992-20.771 11.992a7.5 7.5 0 007.5 12.99l20.771-11.992v23.984a7.5 7.5 0 007.5 7.502zM274.536 433.143a7.497 7.497 0 0010.245 2.745l20.771-11.992v23.984a7.5 7.5 0 0015 0v-23.985l20.771 11.992a7.499 7.499 0 0010.245-2.745 7.5 7.5 0 00-2.745-10.245l-20.771-11.992 20.771-11.992a7.5 7.5 0 00-7.5-12.99l-20.771 11.992V373.93a7.5 7.5 0 00-15 0v23.984l-20.771-11.992a7.5 7.5 0 00-7.5 12.99l20.771 11.992-20.771 11.992a7.503 7.503 0 00-2.745 10.247zM159.811 433.143a7.497 7.497 0 0010.245 2.745l20.771-11.992v23.985a7.5 7.5 0 0015 0v-23.984l20.771 11.992a7.499 7.499 0 0010.245-2.745 7.5 7.5 0 00-2.745-10.245l-20.771-11.992 20.771-11.992a7.5 7.5 0 00-7.5-12.99l-20.771 11.992v-23.984a7.5 7.5 0 00-15 0v23.985l-20.771-11.992a7.5 7.5 0 00-7.5 12.99l20.771 11.992-20.771 11.992a7.497 7.497 0 00-2.745 10.243zM389.261 433.143a7.497 7.497 0 0010.245 2.745l20.771-11.992v23.985a7.5 7.5 0 0015 0v-23.984l20.771 11.992a7.499 7.499 0 0010.245-2.745 7.5 7.5 0 00-2.745-10.245l-20.771-11.992 20.771-11.992a7.5 7.5 0 00-7.5-12.99l-20.771 11.992v-23.984a7.5 7.5 0 00-15 0v23.985l-20.771-11.992c-3.587-2.073-8.174-.842-10.245 2.745s-.842 8.174 2.745 10.245l20.771 11.992-20.771 11.992a7.495 7.495 0 00-2.745 10.243z"></path>
        <path d="M479.67 321.73h-89.53v-71.78a7.5 7.5 0 00-15 0v71.78h-85.397l-3.99-35.454a35.258 35.258 0 005.367-18.746c0-9.738-4.07-19.149-11.167-25.818-7.2-6.767-16.62-10.152-26.515-9.538-17.816 1.1-32.053 15.377-33.115 33.209-.441 7.409 1.385 14.614 5.303 20.899l-3.99 35.447H136.24V197.1c0-4.626 3.764-8.39 8.39-8.39h222.12c4.626 0 8.39 3.764 8.39 8.39v17.85a7.5 7.5 0 0015 0V197.1c0-12.897-10.493-23.39-23.39-23.39h-1.18v-44.1c0-29.337-11.43-56.935-32.189-77.716-20.763-20.741-48.354-32.164-77.69-32.164-60.588 0-109.88 49.292-109.88 109.88v44.1h-1.18c-12.897 0-23.39 10.493-23.39 23.39v124.63H31.71C14.225 321.73 0 335.955 0 353.44v106.5c0 17.485 14.225 31.71 31.71 31.71H83.6a7.5 7.5 0 000-15H31.71c-9.214 0-16.71-7.496-16.71-16.71v-106.5c0-9.214 7.496-16.71 16.71-16.71h447.96c9.214 0 16.71 7.496 16.71 16.71v106.5c0 9.214-7.496 16.71-16.71 16.71H118.6a7.5 7.5 0 000 15h361.07c17.485 0 31.71-14.225 31.71-31.71v-106.5c0-17.485-14.225-31.71-31.71-31.71zM301.43 173.71h-91.48v-44.1c0-25.221 20.519-45.74 45.74-45.74 12.213 0 23.699 4.754 32.334 13.38 8.645 8.654 13.406 20.146 13.406 32.359zm-140.62-44.1c0-52.317 42.563-94.88 94.88-94.88 25.333 0 49.159 9.864 67.084 27.771 17.924 17.943 27.796 41.776 27.796 67.109v44.1h-34.14v-44.1c0-16.217-6.319-31.474-17.8-42.966-11.474-11.461-26.724-17.773-42.94-17.773-33.492 0-60.74 27.248-60.74 60.74v44.1h-34.14zm77.689 148.964c-2.356-3.664-3.463-7.916-3.202-12.298.602-10.104 8.976-18.507 19.069-19.13 5.723-.354 11.164 1.596 15.315 5.497 4.152 3.902 6.439 9.189 6.439 14.887 0 3.934-1.118 7.746-3.236 11.029a13.681 13.681 0 00-2.098 8.86l3.862 34.311h-37.917l3.865-34.346a13.571 13.571 0 00-2.097-8.81z"></path>
      </g>
    </svg>
    );
};

PasswordTwoIcon.defaultProps = {
    color: 'black',
    size: 20
};

export default PasswordTwoIcon;