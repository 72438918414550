import React from "react";

const Icon02d = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -72 512 512"
    >
      <path
        fill="#feef7f"
        d="M296.29 184.18c0 61.918-50.192 112.11-112.11 112.11S72.07 246.097 72.07 184.18 122.262 72.07 184.18 72.07s112.11 50.192 112.11 112.11zm0 0"
      ></path>
      <path
        fill="#ea9a10"
        d="M184.18 296.29c61.918 0 112.11-50.192 112.11-112.11 0-33.528-14.724-63.61-38.048-84.153-43.742 20.387-74.062 64.743-74.062 116.184-34.04 0-61.864 26.55-63.926 60.07 18.133 12.61 40.164 20.008 63.926 20.008zm0 0"
      ></path>
      <path
        fill="#b8ecff"
        d="M456.445 264.258c-1.375 0-2.738.074-4.086.183a128.405 128.405 0 004.086-32.214c0-70.762-57.363-128.125-128.125-128.125-70.761 0-128.125 57.363-128.125 128.125-35.379 0-64.062 28.683-64.062 64.062s28.683 64.063 64.062 64.063h256.25c26.535 0 48.047-21.512 48.047-48.047 0-26.535-21.512-48.047-48.047-48.047zm0 0"
      ></path>
      <path
        fill="#57bfe6"
        d="M456.445 264.258c-1.375 0-2.738.074-4.086.183a128.405 128.405 0 004.086-32.214c0-70.762-57.363-128.125-128.125-128.125-4.05 0-8.054.199-12.011.566 65.129 6.055 116.113 60.848 116.113 127.559 0 11.128-1.422 21.921-4.086 32.214a48.691 48.691 0 014.086-.183c26.535 0 48.047 21.512 48.047 48.047 0 26.535-21.512 48.047-48.047 48.047h24.023c26.535 0 48.047-21.512 48.047-48.047 0-26.535-21.512-48.047-48.047-48.047zm0 0"
      ></path>
      <g fill="#fff">
        <path d="M280.273 312.805h16.016v15.015h-16.016zm0 0M312.305 312.805h16.015v15.015h-16.015zm0 0M344.336 312.805h16.016v15.015h-16.016zm0 0"></path>
      </g>
      <path d="M184.18 127.625v-15.016c-39.465 0-71.57 32.106-71.57 71.57h15.015c0-31.183 25.371-56.554 56.555-56.554zm0 0"></path>
      <path d="M79.578 184.18c0-57.68 46.926-104.602 104.602-104.602 24.578 0 48.457 8.692 67.242 24.473l9.656-11.496c-21.484-18.051-48.793-27.993-76.898-27.993-65.957 0-119.618 53.66-119.618 119.618 0 36.875 16.626 71.129 45.618 93.984l9.297-11.789c-25.36-19.992-39.899-49.95-39.899-82.195zm0 0"></path>
      <path d="M461.648 257.004a135.035 135.035 0 002.305-24.777c0-74.79-60.844-135.633-135.633-135.633-72.394 0-131.722 57.011-135.445 128.496-36.04 3.68-64.25 34.457-64.25 71.45 0 39.464 32.105 71.82 71.57 71.82h256.25c30.633 0 55.555-25.173 55.555-55.805 0-28.875-22.148-52.918-50.352-55.551zm-5.203 96.34h-256.25c-31.183 0-56.554-25.621-56.554-56.805 0-28.719 21.527-52.617 49.289-56.2a135.103 135.103 0 004.03 25.852l14.54-3.773a121.297 121.297 0 01-3.797-30.176c0-66.512 54.11-120.629 120.617-120.629 66.508 0 120.618 54.106 120.618 120.617 0 27.727-9.149 53.793-26.454 75.38L434.2 317c10.778-13.445 18.836-28.836 23.828-45.195 21.621.836 38.957 18.925 38.957 40.75 0 22.355-18.183 40.789-40.539 40.789zm0 0"></path>
      <path d="M240.734 232.227h15.016c0-40.016 32.555-72.57 72.57-72.57V144.64c-48.293 0-87.586 39.289-87.586 87.586zm0 0M0 177.172h48.047v15.016H0zm0 0M176.172 0h15.016v48.047h-15.016zm0 0M314.988 42.742l10.617 10.617-40.039 40.04L274.95 82.78zm0 0M53.367 325.633L42.75 315.016l40.04-40.04 10.616 10.618zm0 0M53.355 42.738l40.04 40.04-10.618 10.617-40.039-40.04zm0 0"></path>
    </svg>
    );
};

Icon02d.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon02d;