import React from 'react';

const GoogleIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <path
        fill="#2196f3"
        d="M500.5 210a4 4 0 014 4v42c0 15.72-1.46 31.09-4.26 46-21.664 115.813-123.693 203.337-245.992 202.494C117.021 503.548 7.198 392.67 7.501 255.44 7.803 118.457 118.947 7.5 256 7.5c67.147 0 128.07 26.638 172.799 69.908 1.615 1.562 1.657 4.136.068 5.725l-59.418 59.418a3.989 3.989 0 01-5.569.078C335.807 115.906 297.823 99.5 256 99.5c-86.365 0-155.911 69.052-156.496 155.415C98.914 341.843 169.209 412.5 256 412.5c70.42 0 129.98-46.52 149.62-110.5H260a4 4 0 01-4-4v-84a4 4 0 014-4z"
      ></path>
      <path
        fill="#1e88e5"
        d="M500.5 210h-30a4 4 0 014 4v42c0 15.72-1.46 31.09-4.26 46-20.667 110.488-114.48 195.225-229.256 202.052 4.391.259 8.812.411 13.264.442 122.299.843 224.329-86.68 245.992-202.494 2.8-14.91 4.26-30.28 4.26-46v-42a4 4 0 00-4-4z"
      ></path>
      <path
        fill="#f44336"
        d="M113.85 190.46l-75.47-54.53C80.72 59.35 162.31 7.5 256 7.5c67.147 0 128.07 26.638 172.799 69.908 1.615 1.562 1.657 4.136.068 5.725L369.45 142.55c-1.527 1.527-3.998 1.574-5.562.085C335.814 115.909 297.827 99.5 256 99.5c-63.03 0-117.36 37.26-142.15 90.96z"
      ></path>
      <path
        fill="#e53935"
        d="M92.063 174.718l21.787 15.742c22.627-49.015 69.869-84.316 125.869-90.106.422-.045.826-.101 1.255-.142A158.669 158.669 0 00226 99.5c-56.938 0-106.561 30.018-133.937 75.218zM398.799 77.408c1.615 1.562 1.657 4.136.068 5.725L350.64 131.36a157.611 157.611 0 0113.241 11.268 3.988 3.988 0 005.569-.078l59.418-59.418c1.589-1.589 1.547-4.163-.068-5.725C384.07 34.138 323.147 7.5 256 7.5c-5.039 0-10.038.168-15.003.464 61.194 3.645 116.428 29.42 157.802 69.444z"
      ></path>
      <path
        fill="#4caf50"
        d="M438.29 424.88C392.91 473.85 328.03 504.5 256 504.5c-97.29 0-181.52-55.91-222.31-137.36l77.05-52.8c23.12 57.53 79.45 98.16 145.26 98.16 41.3 0 78.86-16 106.82-42.14z"
      ></path>
      <path
        fill="#43a047"
        d="M110.74 314.34l-22.72 15.57c26.389 49.16 78.282 82.59 137.98 82.59 5.049 0 10.039-.247 14.965-.715-59.259-5.65-108.887-44.349-130.225-97.445zM256 504.5c72.03 0 136.91-30.65 182.29-79.62l-18.485-13.354c-42.61 53.029-106.531 88.209-178.767 92.522 4.951.295 9.938.452 14.962.452z"
      ></path>
      <path
        fill="#ffc107"
        d="M99.5 256c0 20.62 3.99 40.31 11.24 58.34l-77.05 52.8C16.93 333.7 7.5 295.95 7.5 256c0-43.55 11.2-84.48 30.88-120.07l75.47 54.53C104.64 210.39 99.5 232.6 99.5 256z"
      ></path>
      <path
        fill="#ffb300"
        d="M88.02 329.91l22.72-15.57C103.49 296.31 99.5 276.62 99.5 256c0-23.4 5.14-45.61 14.35-65.54l-21.787-15.742c-14.148 23.36-22.359 50.772-22.559 80.197-.184 27.135 6.54 52.685 18.516 74.995z"
      ></path>
      <path d="M500.5 202.5h-51.961a7.5 7.5 0 000 15H497V256c0 15.007-1.39 30.019-4.132 44.621-8.178 43.72-27.85 82.771-55.529 114.319l-62.971-45.49c17.628-18.448 30.956-40.931 38.422-65.249a7.498 7.498 0 00-7.17-9.701H263.5v-77h152.577a7.5 7.5 0 000-15H260c-6.341 0-11.5 5.159-11.5 11.5v84c0 6.341 5.159 11.5 11.5 11.5h135.112C373.122 367.029 318.442 405 256 405c-32.68 0-63.699-10.38-89.703-30.019a7.5 7.5 0 00-9.04 11.97C185.886 408.572 220.031 420 256 420c35.794 0 69.804-11.328 98.355-32.759a162.164 162.164 0 008.973-7.261l63.687 46.008C383.03 470.188 322.22 497 255.993 497c-.563 0-1.13-.002-1.693-.006-91.081-.628-170.349-52.226-210.778-127.5l63.992-43.852a163.75 163.75 0 0025.209 38.519 7.482 7.482 0 005.639 2.551 7.5 7.5 0 005.633-12.448c-24.102-27.449-37.238-62.713-36.99-99.298C107.557 173.377 174.397 107 256 107c38.417 0 74.893 14.583 102.709 41.061 4.549 4.33 11.596 4.24 16.043-.207l59.418-59.418a11.4 11.4 0 003.364-8.217 11.465 11.465 0 00-3.521-8.202C386.006 25.576 322.786 0 256 0c-38.884 0-76.232 8.498-111.006 25.256a7.5 7.5 0 006.513 13.513C184.233 22.997 219.389 15 256 15c61.694 0 120.159 23.186 165.031 65.363l-54.491 54.491C336.254 107.191 297.138 92 256 92c-43.765 0-84.854 16.869-115.7 47.5-11.888 11.806-21.733 25.152-29.372 39.596l-62.724-45.321c18.592-31.589 44.464-59.001 76.285-79.763a7.5 7.5 0 00-8.196-12.562C43.666 88.836.192 168.826.001 255.423c-.151 68.37 26.12 132.625 73.972 180.929 47.856 48.307 111.861 75.171 180.224 75.643.59.004 1.172.006 1.762.006 59.816-.002 117.937-21.004 163.825-59.245 45.75-38.125 76.942-91.174 87.828-149.371C510.524 287.876 512 271.934 512 256v-42c0-6.341-5.159-11.5-11.5-11.5zM40.948 147.039l63.485 45.87c-8.007 19.362-12.282 40.316-12.429 61.956a164.093 164.093 0 009.656 56.606l-64.873 44.456c-14.047-30.637-21.865-64.674-21.786-100.47.084-38.368 9.198-75.357 25.947-108.418z"></path>
    </svg>
    );
};

GoogleIcon.defaultProps = {
    color: "white",
    size: 18
};

export default GoogleIcon;