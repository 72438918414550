import React from "react";

const Icon04n = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 -88 512 512"
    >
      <path
        fill="#57bfe6"
        d="M328.14 167.828c-1.378 0-2.742.07-4.09.184a128.497 128.497 0 004.09-32.246c0-70.832-57.417-128.25-128.25-128.25S71.64 64.934 71.64 135.766c-35.413 0-64.124 28.707-64.124 64.125 0 35.414 28.71 64.125 64.125 64.125h256.5c26.562 0 48.093-21.532 48.093-48.094s-21.53-48.094-48.093-48.094zm0 0"
      ></path>
      <path
        fill="#b8ecff"
        d="M456.39 231.953c-1.378 0-2.742.07-4.09.184 2.669-10.301 4.09-21.11 4.09-32.246 0-70.832-57.417-128.25-128.25-128.25-70.827 0-128.25 57.418-128.25 128.25-35.413 0-64.124 28.71-64.124 64.125s28.71 64.125 64.125 64.125h256.5c26.562 0 48.093-21.532 48.093-48.094s-21.53-48.094-48.093-48.094zm0 0"
      ></path>
      <g fill="#fff">
        <path d="M280.047 280.547h16.031v15.031h-16.031zm0 0M312.11 280.547h16.03v15.031h-16.03zm0 0M344.172 280.547h16.031v15.031h-16.031zm0 0"></path>
      </g>
      <path
        fill="#57bfe6"
        d="M456.39 231.953c-1.378 0-2.742.07-4.09.184 2.669-10.301 4.09-21.11 4.09-32.246 0-70.832-57.417-128.25-128.25-128.25-4.054 0-8.066.195-12.023.562 65.192 6.067 116.227 60.91 116.227 127.688 0 11.136-1.422 21.945-4.09 32.246a48.785 48.785 0 014.09-.184c26.562 0 48.094 21.531 48.094 48.094s-21.532 48.094-48.094 48.094h24.047c26.562 0 48.093-21.532 48.093-48.094s-21.53-48.094-48.093-48.094zm0 0"
      ></path>
      <path d="M15.031 200.14c0-28.75 21.547-52.667 49.336-56.253a134.994 134.994 0 004.035 25.875l14.555-3.778a121.31 121.31 0 01-3.8-30.203c0-66.574 54.16-120.746 120.734-120.746 34.617 0 67.62 14.895 90.55 40.871l11.27-9.949C275.93 16.75 238.82 0 199.89 0 127.426 0 68.039 57.07 64.313 128.621 28.238 132.305 0 163.113 0 200.141c0 39.504 32.137 71.89 71.64 71.89h40.079v-15.027H71.64c-31.215 0-56.61-25.649-56.61-56.863zm0 0"></path>
      <path d="M199.89 63.121V48.094c-48.343 0-87.671 39.328-87.671 87.672h15.031c0-40.055 32.586-72.645 72.64-72.645zm0 0M461.602 224.691a135.288 135.288 0 002.304-24.8c0-74.864-60.902-135.766-135.765-135.766-72.465 0-131.852 57.07-135.579 128.625-36.074 3.68-64.312 34.488-64.312 71.516 0 39.504 32.137 71.89 71.64 71.89h256.5c30.665 0 55.61-25.195 55.61-55.86 0-28.905-22.172-52.972-50.398-55.605zm-5.211 96.438h-256.5c-31.215 0-56.61-25.649-56.61-56.863 0-28.75 21.547-52.668 49.336-56.254a135.388 135.388 0 004.035 25.879l14.555-3.782a121.314 121.314 0 01-3.805-30.203c0-66.574 54.164-120.746 120.739-120.746s120.734 54.16 120.734 120.735c0 27.753-9.152 53.843-26.477 75.453l11.727 9.402c10.79-13.46 18.855-28.867 23.852-45.242 21.64.836 38.992 18.945 38.992 40.789 0 22.375-18.203 40.832-40.578 40.832zm0 0"></path>
      <path d="M240.469 199.89H255.5c0-40.054 32.586-72.64 72.64-72.64v-15.031c-48.343 0-87.671 39.328-87.671 87.672zm0 0"></path>
    </svg>
    );
};

Icon04n.defaultProps = {
    color: 'black',
    size: 20
};

export default Icon04n;