import React from 'react'

const DeleteUserIcon = ({color, size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0"
      y="0"
      height={size}
      width={size}
      enableBackground="new 0 0 512 512"
      version="1.1"
      viewBox="0 0 512 512"
      xmlSpace="preserve"
    >
      <path
        fill="#29ABE2"
        d="M338.717 345.202c17.112-17.112 39.301-26.032 61.719-26.801-40.295-44.73-98.662-72.857-163.605-72.857-121.583 0-220.146 98.562-220.146 220.146h313.943c-27.479-35.972-24.799-87.6 8.089-120.488z"
      ></path>
      <path
        fill="#FF1D25"
        d="M468.487 345.202c-18.722-18.722-43.523-27.642-68.05-26.801-22.417.769-44.606 9.688-61.719 26.801-32.887 32.887-35.568 84.516-8.09 120.488a92.59 92.59 0 008.09 9.281c35.835 35.835 93.934 35.835 129.769 0 35.834-35.834 35.834-93.934 0-129.769z"
      ></path>
      <circle cx="236.827" cy="127.869" r="117.669" fill="#29ABE2"></circle>
      <path d="M59.965 433.237c-.788 0-1.59-.092-2.392-.285-5.476-1.317-8.851-6.823-7.535-12.301 11.978-49.846 44.199-93.579 88.403-119.988 4.84-2.889 11.1-1.311 13.988 3.526 2.889 4.837 1.311 11.099-3.525 13.987-39.522 23.609-68.326 62.697-79.03 107.24-1.123 4.679-5.301 7.821-9.909 7.821zM194.655 298.903c-4.618 0-8.803-3.158-9.916-7.847-1.301-5.482 2.088-10.979 7.569-12.28 14.504-3.443 29.483-5.188 44.522-5.188 5.633 0 10.2 4.567 10.2 10.2 0 5.634-4.568 10.2-10.2 10.2-13.455 0-26.849 1.56-39.81 4.636-.792.19-1.584.279-2.365.279z"></path>
      <path d="M475.699 337.989c-19.549-19.549-45.125-29.477-70.802-29.801a232.245 232.245 0 00-5.187-5.378c-29.765-29.765-66.243-50.374-106.018-60.428 42.052-20.966 71.01-64.417 71.01-114.511C364.703 57.364 307.341 0 236.831 0S108.958 57.364 108.958 127.872c0 50.094 28.958 93.545 71.01 114.511-39.774 10.054-76.253 30.664-106.018 60.428-43.505 43.508-67.465 101.352-67.465 162.88 0 5.634 4.568 10.2 10.2 10.2h309.036a103.69 103.69 0 005.782 6.294c19.878 19.877 45.988 29.815 72.1 29.815 26.11 0 52.22-9.939 72.098-29.815 39.752-39.755 39.752-104.44-.002-144.196zM129.358 127.872c0-59.26 48.212-107.472 107.473-107.472s107.472 48.212 107.472 107.472-48.212 107.473-107.472 107.473-107.473-48.212-107.473-107.473zM27.13 455.491c5.338-111.046 97.355-199.746 209.701-199.746 54.713 0 104.587 21.054 141.992 55.465-17.351 4.318-33.781 13.241-47.319 26.78-31.735 31.735-38.133 79.355-19.203 117.501H27.13zm434.144 12.268c-31.8 31.8-83.544 31.801-115.344 0s-31.8-83.544 0-115.344c15.902-15.902 36.784-23.851 57.673-23.851 20.884 0 41.774 7.952 57.672 23.85 31.799 31.801 31.799 83.545-.001 115.345z"></path>
      <path d="M439.241 374.659c-3.983-3.983-10.44-3.983-14.426 0L402.4 397.074l-22.415-22.415c-3.984-3.983-10.441-3.983-14.426 0-3.983 3.983-3.983 10.442 0 14.425l22.415 22.415-22.415 22.415c-3.983 3.983-3.983 10.442 0 14.426a10.168 10.168 0 007.213 2.988c2.61 0 5.22-.997 7.213-2.988l22.415-22.415 22.415 22.415a10.168 10.168 0 007.213 2.988c2.61 0 5.22-.997 7.213-2.988 3.983-3.984 3.983-10.442 0-14.426l-22.415-22.415 22.415-22.415c3.983-3.983 3.983-10.442 0-14.425z"></path>
    </svg>
    )


}

DeleteUserIcon.defaultProps = {
    color: "white",
    size: 18
}

export default DeleteUserIcon