import React from "react";

const Icon11d = ({size}) => {
    return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 512 512"
    >
      <path
        fill="#feef7f"
        d="M296.29 184.18c0 61.918-50.192 112.11-112.11 112.11S72.07 246.097 72.07 184.18 122.262 72.07 184.18 72.07s112.11 50.192 112.11 112.11zm0 0"
      ></path>
      <path
        fill="#ea9a10"
        d="M184.18 296.29c61.918 0 112.11-50.192 112.11-112.11 0-33.528-14.724-63.61-38.048-84.153-43.742 20.387-74.062 64.743-74.062 116.184-34.04 0-61.864 26.55-63.926 60.07 18.133 12.61 40.164 20.008 63.926 20.008zm0 0"
      ></path>
      <g fill="#b8ecff">
        <path d="M456.445 264.258c-1.375 0-2.738.074-4.086.183a128.405 128.405 0 004.086-32.214c0-70.762-57.363-128.125-128.125-128.125-70.761 0-128.125 57.363-128.125 128.125-35.379 0-64.062 28.683-64.062 64.062s28.683 64.063 64.062 64.063h256.25c26.535 0 48.047-21.512 48.047-48.047 0-26.535-21.512-48.047-48.047-48.047zm0 0M448.438 440.43c0 8.847-7.168 16.015-16.016 16.015-8.844 0-16.016-7.168-16.016-16.015 0-8.844 16.016-40.04 16.016-40.04s16.015 31.196 16.015 40.04zm0 0M384.375 488.477c0 8.847-7.168 16.015-16.016 16.015-8.843 0-16.015-7.168-16.015-16.015 0-8.844 16.015-40.04 16.015-40.04s16.016 31.196 16.016 40.04zm0 0M128.125 440.43c0 8.847 7.172 16.015 16.016 16.015 8.847 0 16.015-7.168 16.015-16.015 0-8.844-16.015-40.04-16.015-40.04s-16.016 31.196-16.016 40.04zm0 0M192.188 488.477c0 8.847 7.171 16.015 16.015 16.015 8.848 0 16.016-7.168 16.016-16.015 0-8.844-16.016-40.04-16.016-40.04s-16.016 31.196-16.016 40.04zm0 0"></path>
      </g>
      <path
        fill="#57bfe6"
        d="M456.445 264.258c-1.375 0-2.738.074-4.086.183a128.405 128.405 0 004.086-32.214c0-70.762-57.363-128.125-128.125-128.125-4.05 0-8.054.199-12.011.566 65.129 6.055 116.113 60.848 116.113 127.559 0 11.128-1.422 21.921-4.086 32.214a48.691 48.691 0 014.086-.183c26.535 0 48.047 21.512 48.047 48.047 0 26.535-21.512 48.047-48.047 48.047h24.023c26.535 0 48.047-21.512 48.047-48.047 0-26.535-21.512-48.047-48.047-48.047zm0 0"
      ></path>
      <path
        fill="#fff"
        d="M280.273 272.766h16.016v15.015h-16.016zm0 0M312.305 272.766h16.015v15.015h-16.015zm0 0M344.336 272.766h16.016v15.015h-16.016zm0 0"
      ></path>
      <path
        fill="#feef7f"
        d="M328.32 360.352l24.024-48.047h-56.055l-40.039 80.078h56.055l-40.04 80.078h8.008l104.102-112.11zm0 0"
      ></path>
      <path
        fill="#ea9a10"
        d="M328.32 360.352l24.024-48.047H328.32l-24.023 48.047zm0 0M360.352 360.352l-69.403 74.742-18.683 37.367h8.007l104.102-112.11h-24.023zm0 0"
      ></path>
      <path d="M127.625 184.18c0-31.184 25.371-56.555 56.555-56.555v-15.016c-39.465 0-71.57 32.106-71.57 71.57zm0 0"></path>
      <path d="M79.578 184.18c0-57.68 46.922-104.602 104.602-104.602 24.578 0 48.457 8.692 67.242 24.473l9.656-11.496c-21.484-18.051-48.793-27.993-76.898-27.993-65.957 0-119.618 53.66-119.618 119.618 0 36.875 16.626 71.129 45.618 93.984l9.297-11.789c-25.356-19.992-39.899-49.95-39.899-82.195zm0 0M432.422 383.953l-6.68 13.008c-3.949 7.695-16.844 33.578-16.844 43.469 0 12.972 10.555 23.523 23.524 23.523 12.973 0 23.523-10.55 23.523-23.523 0-9.89-12.894-35.774-16.843-43.47zm0 64.985c-4.692 0-8.508-3.817-8.508-8.508.016-2.934 3.774-12.5 8.508-22.836 4.738 10.336 8.492 19.898 8.508 22.836 0 4.691-3.817 8.507-8.508 8.507zm0 0M368.36 432l-6.68 13.008c-3.95 7.699-16.844 33.578-16.844 43.469 0 12.972 10.555 23.523 23.523 23.523 12.973 0 23.524-10.55 23.524-23.523 0-9.891-12.895-35.77-16.844-43.47zm0 64.984c-4.692 0-8.508-3.816-8.508-8.507.015-2.934 3.773-12.5 8.507-22.836 4.739 10.336 8.493 19.898 8.508 22.836 0 4.691-3.816 8.507-8.508 8.507zm0 0M144.14 383.953l-6.68 13.008c-3.948 7.695-16.843 33.578-16.843 43.469 0 12.972 10.555 23.523 23.524 23.523 12.972 0 23.523-10.55 23.523-23.523 0-9.89-12.894-35.774-16.844-43.47zm0 64.985c-4.69 0-8.507-3.817-8.507-8.508.015-2.934 3.773-12.5 8.508-22.836 4.738 10.336 8.492 19.898 8.507 22.836 0 4.691-3.816 8.507-8.507 8.507zm0 0M208.203 432l-6.68 13.008c-3.949 7.699-16.843 33.578-16.843 43.469 0 12.972 10.554 23.523 23.523 23.523 12.973 0 23.524-10.55 23.524-23.523 0-9.891-12.895-35.77-16.844-43.47zm0 64.984c-4.691 0-8.508-3.816-8.508-8.507.016-2.934 3.774-12.5 8.508-22.836 4.738 10.336 8.492 19.898 8.508 22.836 0 4.691-3.816 8.507-8.508 8.507zm0 0"></path>
      <path d="M461.648 257.004a135.035 135.035 0 002.305-24.777c0-74.79-60.844-135.633-135.633-135.633-72.394 0-131.722 57.011-135.445 128.5-36.04 3.676-64.25 34.453-64.25 71.445 0 39.465 32.105 71.82 71.57 71.82h40.04v-15.015h-40.04c-31.183 0-56.554-25.621-56.554-56.805 0-28.719 21.527-52.617 49.289-56.2a135.055 135.055 0 004.03 25.852l14.54-3.773a121.274 121.274 0 01-3.797-30.176c0-66.508 54.11-120.625 120.617-120.625 66.508 0 120.618 54.106 120.618 120.613 0 27.727-9.149 53.793-26.454 75.383L434.2 317c10.778-13.441 18.836-28.836 23.828-45.195 21.621.836 38.957 18.93 38.957 40.75 0 22.355-18.187 40.789-40.539 40.789h-40.039v15.015h40.04c30.632 0 55.554-25.172 55.554-55.804 0-28.875-22.148-52.918-50.352-55.551zm0 0"></path>
      <path d="M328.32 159.656v-15.015c-48.293 0-87.586 39.289-87.586 87.586h15.016c0-40.016 32.555-72.57 72.57-72.57zm0 0M0 177.172h48.047v15.016H0zm0 0M176.172 0h15.016v48.047h-15.016zm0 0M314.988 42.742l10.617 10.617-40.039 40.04L274.95 82.78zm0 0M53.355 325.625l-10.617-10.617 40.04-40.04 10.617 10.618zm0 0M53.355 42.738l40.04 40.04-10.618 10.617-40.039-40.04zm0 0M364.492 305.297h-72.844l-47.546 95.094h56.058l-40.043 80.078h23.43l118.047-127.125h-61.125zm2.668 63.062l-74.098 79.797 31.391-62.781h-56.055l32.532-65.063h39.265l-24.023 48.047zm0 0"></path>
    </svg>
    );
};

Icon11d.defaultProps = {
    size: 20
};

export default Icon11d;