import React from 'react';

const FaqIcon = ({color, size}) => {
    return (


<svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 512 512"
    >
      <path d="M21.728 331H151c82.705 0 150-67.295 150-150S233.705 31 151 31 0 98.295 0 181c0 39.536 16.586 77.271 44.11 105.234zM166 271h-30v-30h30zM151 91c33.091 0 60 26.909 60 60 0 22.412-12.349 42.803-32.227 53.188C170.893 208.319 166 217.123 166 226h-30c0-20.83 11.06-39.082 28.857-48.384C174.818 172.401 181 162.206 181 151c0-16.538-13.462-30-30-30s-30 13.462-30 30H91c0-33.091 26.909-60 60-60z"></path>
      <path d="M330.841 184.144c-1.326 76.111-50.079 140.689-117.94 165.659C222.272 423.567 284.733 481 361 481h129.272l-22.383-44.766C495.414 408.271 512 370.536 512 331c0-82.842-68.158-150-151-150a146.118 146.118 0 00-30.159 3.144zM436 376H316v-30h120zm0-60H316v-30h120z"></path>
    </svg>
    );
};

FaqIcon.defaultProps = {
    color: "white",
    size: 20
};

export default FaqIcon;