import React from 'react';

const LikeHeartIcon = ({type ,color, size}) => {
    return (
        <React.Fragment>
            {type === 'like' ?
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512"
                     fill={color}
                     height={size}
                     width={size}
                >
                    <path
                        d="M288.578 183.578L256 216.141l-32.578-32.563C212.098 172.238 197.023 166 181 166s-31.098 6.238-42.422 17.578C127.238 194.914 121 209.977 121 226s6.238 31.086 17.578 42.422L256 385.859l117.422-117.437C384.762 257.086 391 242.023 391 226s-6.238-31.086-17.578-42.422c-22.649-22.676-62.195-22.676-84.844 0zm0 0"></path>
                    <path
                        d="M256 0C115.39 0 0 115.39 0 256s115.39 256 256 256 256-115.39 256-256S396.61 0 256 0zm138.633 289.633L256 428.28 117.367 289.633C100.36 272.64 91 250.039 91 226s9.36-46.64 26.367-63.633c33.953-34.015 93.313-34.015 127.266 0L256 173.72l11.367-11.352c33.953-34.015 93.313-34.015 127.266 0C411.64 179.36 421 201.961 421 226s-9.36 46.64-26.367 63.633zm0 0"></path>
                </svg> :
                <svg xmlns="http://www.w3.org/2000/svg"
                     viewBox="0 0 512 512"
                     fill={color}
                     height={size}
                     width={size}
                >
                    <path
                        d="M256 0C187.887 0 123.672 26.703 75.187 75.188S0 187.887 0 256c0 140.969 115.05 256 256 256 140.969 0 256-115.05 256-256C512 115.031 396.95 0 256 0zm0 482C131.383 482 30 380.617 30 256S131.383 30 256 30s226 101.383 226 226-101.383 226-226 226zm0 0"></path>
                    <path
                        d="M394.64 162.371c-35.148-35.18-92.117-35.2-127.273-.008L256 173.727l-11.36-11.356c-35.148-35.18-92.117-35.2-127.273-.008-35.18 35.164-35.187 92.102-.004 127.27L256 428.285l138.633-138.648c35.176-35.16 35.191-92.098.008-127.266zm-21.218 106.05L256 385.856 138.578 268.418c-23.45-23.438-23.465-61.387.008-84.844 23.422-23.445 61.394-23.46 84.84.008L256 216.137l32.586-32.563c23.422-23.445 61.394-23.46 84.836.008 23.453 23.438 23.46 61.39 0 84.84zm0 0"></path>
                </svg>
            }
        </React.Fragment>
    );
};

LikeHeartIcon.defaultProps = {
    color: "white",
    size: 20
};

export default LikeHeartIcon;