import React from 'react';

const CommentIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 -16 512 512"
    >
      <path d="M196 60c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 0M196 120c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 0M73 240c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 0M73 300c-5.52 0-10 4.48-10 10s4.48 10 10 10 10-4.48 10-10-4.48-10-10-10zm0 0"></path>
      <path d="M482 0H156c-16.543 0-30 13.457-30 30v130H30c-16.543 0-30 13.457-30 30v180c0 16.543 13.457 30 30 30h33v70a9.998 9.998 0 0017.07 7.07L157.14 400H276c16.543 0 30-13.457 30-30v-90h31.86l97.07 97.07A9.998 9.998 0 00452 370v-90h30c16.543 0 30-13.457 30-30V30c0-16.543-13.457-30-30-30zM286 370c0 5.516-4.484 10-10 10H153a10 10 0 00-7.07 2.93L83 445.855V390c0-5.523-4.477-10-10-10H30c-5.516 0-10-4.484-10-10V190c0-5.516 4.484-10 10-10h246c5.516 0 10 4.484 10 10zm206-120c0 5.516-4.484 10-10 10h-40c-5.523 0-10 4.477-10 10v75.855l-82.93-82.925A10 10 0 00342 260h-36v-60h136c5.523 0 10-4.477 10-10s-4.477-10-10-10H304.281c-4.129-11.64-15.246-20-28.281-20H146V30c0-5.516 4.484-10 10-10h326c5.516 0 10 4.484 10 10zm0 0"></path>
      <path d="M236 240H113c-5.523 0-10 4.477-10 10s4.477 10 10 10h123c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0 0M442 60H236c-5.523 0-10 4.477-10 10s4.477 10 10 10h206c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0 0M442 120H236c-5.523 0-10 4.477-10 10s4.477 10 10 10h206c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0 0M236 300H113c-5.523 0-10 4.477-10 10s4.477 10 10 10h123c5.523 0 10-4.477 10-10s-4.477-10-10-10zm0 0"></path>
    </svg>
    );

};

CommentIcon.defaultProps = {
    color: "white",
    size: 20
};

export default CommentIcon;