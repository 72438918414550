import React from 'react';

const FollowIcon = ({color, size}) => {
    return (


<svg height={size} viewBox="-62 0 512 512" fill={color} width={size} xmlns="http://www.w3.org/2000/svg"><path d="m0 467c0 8.285156 6.714844 15 15 15h184.984375c-26.355469-25.882812-48.828125-56.308594-48.828125-93.632812 0-48.429688 35.976562-86.367188 81.914062-86.367188 18.402344 0 34.011719 6.445312 46.769532 16.191406-28.859375-50.640625-85.660156-83.285156-149.921875-74.875-75.554688 9.894532-129.917969 78.234375-129.917969 154.429688zm0 0"/><path d="m150 212c57.988281 0 105-47.011719 105-105s-47.011719-107-105-107-105 49.011719-105 107 47.011719 105 105 105zm-45-120c8.289062 0 15 6.710938 15 15 0 16.539062 13.460938 30 30 30s30-13.460938 30-30c0-8.289062 6.710938-15 15-15s15 6.710938 15 15c0 33.089844-26.910156 60-60 60s-60-26.910156-60-60c0-8.289062 6.710938-15 15-15zm0 0"/><path d="m285 376.984375v.03125l-.015625-.046875c-4.746094-16.699219-21.019531-44.96875-51.914063-44.96875-29.589843 0-51.914062 24.230469-51.914062 56.367188 0 48.34375 58.023438 87.726562 94.101562 120.042968 2.8125 2.390625 6.269532 3.589844 9.742188 3.589844s6.929688-1.199219 9.742188-3.589844c35.914062-32.167968 94.101562-71.660156 94.101562-120.042968 0-32.140626-22.324219-56.367188-51.914062-56.367188-30.734376 0-47.066407 28.183594-51.929688 44.984375zm0 0"/></svg>    );
};

FollowIcon.defaultProps = {
    color: "white",
    size: 20
};

export default FollowIcon;