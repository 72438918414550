import Icon01d from "./WeatherIcons/Icon01d";
import Icon01n from "./WeatherIcons/Icon01n";
import Icon02d from "./WeatherIcons/Icon02d";
import Icon02n from "./WeatherIcons/Icon02n";
import Icon03d from "./WeatherIcons/Icon03d";
import Icon03n from "./WeatherIcons/Icon03n";
import Icon04d from "./WeatherIcons/Icon04d";
import Icon04n from "./WeatherIcons/Icon04n";
import Icon09d from "./WeatherIcons/Icon09d";
import Icon09n from "./WeatherIcons/Icon09n";
import Icon10d from "./WeatherIcons/Icon10d";
import Icon10n from "./WeatherIcons/Icon10n";
import Icon11d from "./WeatherIcons/Icon11d";
import Icon11n from "./WeatherIcons/Icon11n";
import Icon13d from "./WeatherIcons/Icon13d";
import Icon13n from "./WeatherIcons/Icon13n";
import Icon50d from "./WeatherIcons/Icon50d";
import Icon50n from "./WeatherIcons/Icon50n";

const size = 34

const WeatherIcons = {
    '01d': <Icon01d size={size}/>,
    '01n': <Icon01n size={size}/>,
    '02d': <Icon02d size={size}/>,
    '02n': <Icon02n size={size}/>,
    '03d': <Icon03d size={size}/>,
    '03n': <Icon03n size={size}/>,
    '04d': <Icon04d size={size}/>,
    '04n': <Icon04n size={size}/>,
    '09d': <Icon09d size={size}/>,
    '09n': <Icon09n size={size}/>,
    '10d': <Icon10d size={size}/>,
    '10n': <Icon10n size={size}/>,
    '11d': <Icon11d size={size}/>,
    '11n': <Icon11n size={size}/>,
    '13d': <Icon13d size={size}/>,
    '13n': <Icon13n size={size}/>,
    '50d': <Icon50d size={size}/>,
    '50n': <Icon50n size={size}/>,
}

export default WeatherIcons;




