import React from 'react';

const SavedIcon = ({color, size}) => {
    return (

    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      width={size}
      x="0"
      y="0"
      enableBackground="new 0 0 286.036 286.036"
      version="1.1"
      viewBox="0 0 286.036 286.036"
      xmlSpace="preserve"
    >
      <path
        fill="#39B29D"
        d="M231.641 113.009c-3.915-40.789-37.875-72.792-79.684-72.792-32.351 0-60.053 19.201-72.819 46.752-3.844-1.225-7.849-2.056-12.095-2.056-22.214 0-40.226 18.021-40.226 40.226 0 4.416.885 8.591 2.199 12.551C11.737 147.765 0 166.26 0 187.696c0 32.092 26.013 58.105 58.105 58.105v.018h160.896v-.018c37.044 0 67.035-30.009 67.035-67.044 0-32.682-23.421-59.83-54.395-65.748zm-54.833 51.463h-15.912v35.864c0 4.943-3.987 8.957-8.939 8.957h-17.878c-4.934 0-8.939-4.014-8.939-8.957v-35.864h-15.921c-9.708 0-13.668-6.481-8.823-14.383l33.799-33.316c6.624-6.615 10.816-6.838 17.646 0l33.799 33.316c4.863 7.911.876 14.383-8.832 14.383z"
      ></path>
    </svg>
    );
};

SavedIcon.defaultProps = {
    color: "black",
    size: 20
};

export default SavedIcon;