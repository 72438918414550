import React from 'react';

const ProfileIcon = ({color, size}) => {
    return (


    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill={color}
      viewBox="0 0 512 512"
    >
      <path d="M124.828 466.341v-45.408c0-8.284 6.716-15 15-15h278.003V18.734C417.831 8.404 409.427 0 399.096 0H52.244C41.914 0 33.51 8.404 33.51 18.734V466.34c2.515 60.589 88.826 60.542 91.318.001zM277.514 73.169h77.116c19.902.793 19.887 29.215 0 30h-77.116c-19.902-.793-19.886-29.215 0-30zm0 76.5h77.116c19.902.793 19.887 29.215 0 30h-77.116c-19.902-.793-19.886-29.215 0-30zm0 76.501h77.116c19.902.793 19.887 29.215 0 30h-77.116c-19.902-.793-19.886-29.215 0-30zm-206.487 1.237c0-26.761 13.998-50.3 35.045-63.726-31.158-34.306-6.233-90.682 40.567-90.512 46.797-.171 71.725 56.215 40.565 90.512 21.046 13.426 35.045 36.965 35.045 63.726v13.763c0 8.284-6.716 15-15 15H86.027c-8.284 0-15-6.716-15-15zm0 90.264c0-8.284 6.716-15 15-15h268.604c19.902.793 19.887 29.215 0 30H86.027c-8.284 0-15-6.716-15-15z"></path>
      <path d="M478.49 454.667c0-10.33-8.404-18.734-18.734-18.734H154.828v30.408c0 17.137-5.727 32.961-15.367 45.659h293.37c25.177 0 45.659-20.482 45.659-45.659zM192.232 226.17c-.658-24.549-20.833-44.316-45.538-44.316h-.113c-24.705 0-44.879 19.768-45.538 44.316zM146.638 151.854c13.463 0 24.416-10.92 24.416-24.343-1.34-32.293-47.496-32.283-48.832 0 0 13.423 10.953 24.343 24.416 24.343z"></path>
    </svg>
    );
};

ProfileIcon.defaultProps = {
    color: "white",
    size: 20
};

export default ProfileIcon;