import React from 'react';

const AnchorIcon = ({color, size}) => {
    return (
<svg height={size} width={size}  fill={color} xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" x="0px" y="0px" viewBox="0 0 100 100" enableBackground="new 0 0 100 100"
     xmlSpace="preserve"><path d="M82.64,61.18l-7.677-9.568c-0.02-0.025-0.049-0.016-0.051,0.016l-0.948,12.23l2.545-2.9  c1.16,5.284-0.802,10.071-4.54,12.671c-3.679,2.559-8.745,2.802-11.134,2.86c-6.544,0.158-7.071-4.98-7.071-7.065V39.691  l12.394-2.057v-1.611l-12.394-2.057V21.047c2.36-1.323,3.96-3.846,3.96-6.738c0-4.259-3.465-7.724-7.723-7.724  c-4.258,0-7.723,3.465-7.723,7.724c0,2.892,1.601,5.415,3.96,6.738v12.919l-12.394,2.057v1.611l12.394,2.057v29.734  c0,2.084-0.528,7.223-7.072,7.065c-2.39-0.058-7.456-0.3-11.134-2.86c-3.738-2.601-5.7-7.387-4.54-12.671l2.545,2.9l-0.948-12.23  c-0.003-0.032-0.031-0.041-0.051-0.016L17.36,61.18l3.5-0.9c-0.687,2.286-1.345,5.444-1.043,8.138  C20.41,73.719,24.135,80.4,31.77,82.051c7.404,1.601,9.791,4.462,13.425,7.419C48.157,91.88,50,93.415,50,93.415  s1.843-1.535,4.805-3.945c3.635-2.957,6.022-5.818,13.425-7.419c7.635-1.651,11.36-8.332,11.953-13.631  c0.302-2.695-0.357-5.853-1.043-8.138L82.64,61.18z M50,17.843c-1.948,0-3.534-1.585-3.534-3.534c0-1.948,1.585-3.534,3.534-3.534  c1.948,0,3.534,1.585,3.534,3.534C53.534,16.257,51.948,17.843,50,17.843z"></path></svg>
    );
};

AnchorIcon.defaultProps = {
    color: 'black',
    size: 20
};

export default AnchorIcon;
